var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var tV,uV,mV,CV,rV,ela;$CLJS.nV=function(a,b){a=mV(a,b);return $CLJS.m(a)?a:$CLJS.nl};$CLJS.oV=function(a,b){$CLJS.dj.D($CLJS.EH,$CLJS.rG,a,b)};$CLJS.pV=function(a,b,c){a=$CLJS.ry.h(a,c);return a.g?a.g(b):a.call(null,b)};$CLJS.qV=function(a,b){return $CLJS.pV(a,b,null)};tV=function(){$CLJS.kL(rV,$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Gt],null),$CLJS.Pe($CLJS.Le,$CLJS.r(sV))))};
uV=function(){return $CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,2,[$CLJS.wk,$CLJS.C,$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return $CLJS.qe(a)?["Invalid ",$CLJS.aj.l($CLJS.G([$CLJS.C(a)]))," clause: ",$CLJS.aj.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null)],null),$CLJS.rg.g(function(a){return new $CLJS.S(null,2,5,$CLJS.T,[a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.Oi.h("mbql.clause",$CLJS.ui(a))],null)],null)}),$CLJS.r(sV))};
$CLJS.vV=function(a,b){var c=$CLJS.Oi.h("mbql.clause",$CLJS.ui(a));$CLJS.kL(c,b);$CLJS.Ie($CLJS.r(sV),a)||$CLJS.dj.j(sV,$CLJS.kf,a);return null};$CLJS.wV=function(a,b,c){$CLJS.vV(a,c);$CLJS.DL.o(null,a,function(){return b});return null};$CLJS.zV=function(a,b){$CLJS.R.D(xV,yV,a,b)};$CLJS.BV=function(a,b){$CLJS.R.D(xV,AV,a,b)};
mV=function mV(a,b){return $CLJS.F.h(a,$CLJS.nl)?null:$CLJS.F.h(b,$CLJS.nl)?null:$CLJS.PH(a,b)?b:$CLJS.PH(b,a)?a:$CLJS.Xf(function(d){return $CLJS.Xf(function(e){return $CLJS.F.h(new $CLJS.S(null,2,5,$CLJS.T,[d,e],null),new $CLJS.S(null,2,5,$CLJS.T,[a,b],null))?null:mV.h?mV.h(d,e):mV.call(null,d,e)},$CLJS.nf(b,$CLJS.oj(b)))},$CLJS.nf(a,$CLJS.oj(a)))};CV=new $CLJS.N("metabase.lib.schema.mbql-clause","clause*","metabase.lib.schema.mbql-clause/clause*",1127493678);
$CLJS.DV=new $CLJS.N(null,"short","short",1928760516);rV=new $CLJS.N("metabase.lib.schema.mbql-clause","tag","metabase.lib.schema.mbql-clause/tag",-405459143);$CLJS.EV=new $CLJS.N("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);ela=new $CLJS.N("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);var sV=$CLJS.cj.g($CLJS.oi);$CLJS.GH(sV,ela,function(){tV();return $CLJS.kL(CV,uV())});tV();$CLJS.kL(CV,uV());$CLJS.kL($CLJS.FL,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,rV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lm,$CLJS.Lb],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,CV],null)],null));
var AV=function AV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return AV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
AV.l=function(a,b){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,$CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.At,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ul,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Nk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null)],null)],null),b)],null)};AV.v=1;
AV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};var yV=function yV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yV.l(arguments[0],1<c.length?new $CLJS.z(c.slice(1),0,null):null)};
yV.l=function(a,b){return $CLJS.oh.h(new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lt,new $CLJS.k(null,1,[$CLJS.dv,["Valid ",$CLJS.p.g(a)," clause"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null),b)};yV.v=1;yV.B=function(a){var b=$CLJS.C(a);a=$CLJS.D(a);return this.l(b,a)};
var xV=function xV(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xV.l(arguments[0],arguments[1],2<c.length?new $CLJS.z(c.slice(2),0,null):null)};xV.l=function(a,b,c){c=$CLJS.F.h($CLJS.C(c),$CLJS.xu)?$CLJS.nf($CLJS.Zd(c),$CLJS.kg(2,c)):$CLJS.nf(null,c);var d=$CLJS.A(c);c=$CLJS.C(d);d=$CLJS.D(d);a=$CLJS.R.j(a,b,d);return $CLJS.m(c)?$CLJS.wV(b,c,a):$CLJS.vV(b,a)};xV.v=2;
xV.B=function(a){var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);c=$CLJS.D(c);return this.l(b,a,c)};