var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var uZ,vZ,zna,zZ,AZ,Bna,BZ,CZ,EZ,FZ,GZ,HZ,IZ,KZ,LZ,MZ,Cna,PZ,QZ,Ana;
uZ=function(a,b){a=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Lg,a],null)],null);for(var c=$CLJS.Lg;;){var d=$CLJS.he(a);if($CLJS.m(d)){var e=d,f=$CLJS.J(e,0,null),g=$CLJS.J(e,1,null),l=e,n=$CLJS.ie(a);a=function(q,u,v,x,y,B,H,I){return function(Q){return $CLJS.oh.j(v,$CLJS.rg.g(function(Y,aa,ha,qa,Ea){return function(jb){var lb=$CLJS.J(jb,0,null);jb=$CLJS.J(jb,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kf.h(Ea,lb),jb],null)}}(q,u,v,x,y,B,H,I)),Q)}}(a,c,n,e,f,g,l,d);
$CLJS.m(b.g?b.g(g):b.call(null,g))?(a=n,c=$CLJS.kf.h(c,l)):a=$CLJS.oe(g)?a(g):$CLJS.ne(g)?a($CLJS.dg($CLJS.Tm,g)):n}else return c}};vZ=function(a,b,c){return $CLJS.qe(c)&&$CLJS.F.h(a,$CLJS.C(c))&&!$CLJS.Ie(b,$CLJS.M.h(c,2))};$CLJS.wZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Km.h($CLJS.OL,$CLJS.Zd)),$CLJS.RQ.g(a));return uZ($CLJS.Cm.l(a,$CLJS.XO,$CLJS.G([$CLJS.pZ])),function(c){return vZ($CLJS.AG,b,c)})};
$CLJS.xZ=function(a){var b=$CLJS.oh.j($CLJS.oi,$CLJS.rg.g($CLJS.Km.h($CLJS.IG,$CLJS.Zd)),$CLJS.PP.g(a));return uZ($CLJS.Cm.l(a,$CLJS.XO,$CLJS.G([$CLJS.pZ])),function(c){return vZ($CLJS.PP,b,c)})};zna=function(a){function b(d){return $CLJS.sg(c,$CLJS.G([$CLJS.XO.g(d)]))}function c(d){return $CLJS.nf($CLJS.yP.g(d),$CLJS.sg(b,$CLJS.G([$CLJS.iZ.g(d)])))}return $CLJS.m($CLJS.yZ.g(a))?$CLJS.ag(!0):$CLJS.si(b(a))};
zZ=function(a){for(var b=$CLJS.ag(!1),c=$CLJS.A(a),d=$CLJS.C(c),e=$CLJS.D(c),f=b,g=0,l=a;;){var n=f,q=g,u=l,v=$CLJS.A(u),x=$CLJS.C(v),y=$CLJS.D(v),B=x,H=y,I=$CLJS.Mm.h(n,zna(B));f=$CLJS.C($CLJS.A($CLJS.ug($CLJS.Hb,function(Q,Y,aa,ha,qa,Ea){return function Mb(lb,Fb){try{if($CLJS.qe(Fb)&&3===$CLJS.E(Fb))try{var ac=$CLJS.Td(Fb,0);if($CLJS.O(ac,$CLJS.GQ))try{var Tb=$CLJS.Td(Fb,1);if($CLJS.m($CLJS.oQ.g(Tb)))try{var Ub=$CLJS.Td(Fb,1);if(null!=Ub?Ub.C&256||$CLJS.t===Ub.bg||(Ub.C?0:$CLJS.Nb($CLJS.lc,Ub)):
$CLJS.Nb($CLJS.lc,Ub))try{var ge=$CLJS.M.j(Ub,$CLJS.oQ,$CLJS.bQ);if($CLJS.m($CLJS.$f(ha)(ge))){var $z=$CLJS.M.h(Ub,$CLJS.oQ);$CLJS.Td(Fb,2);return new $CLJS.S(null,1,5,$CLJS.T,[["Invalid :field reference in stage ",$CLJS.p.g(Ea),": no join named ",$CLJS.aj.l($CLJS.G([$z]))].join("")],null)}throw $CLJS.Z;}catch(Og){if(Og instanceof Error){var qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;
}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)throw $CLJS.Z;throw qj;}throw Og;}else throw $CLJS.Z;}catch(Og){if(Og instanceof Error){qj=Og;if(qj===$CLJS.Z)return $CLJS.tZ(Mb,lb,Fb);throw qj;}throw Og;}}}(f,g,l,I,n,q,u,v,x,y,B,H,b,0,a,a,c,d,e,d,e)($CLJS.Lg,$CLJS.Cm.l(B,$CLJS.XO,$CLJS.G([Ana]))))));if($CLJS.m(f))return f;if($CLJS.A(H))g=q+1,f=I,l=H;else return null}};
AZ=new $CLJS.N("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);Bna=new $CLJS.N("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);BZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);CZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.DZ=new $CLJS.N("mbql.stage","mbql","mbql.stage/mbql",1578747798);
EZ=new $CLJS.N("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);FZ=new $CLJS.N("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);GZ=new $CLJS.N("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);HZ=new $CLJS.N("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);
IZ=new $CLJS.N("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.JZ=new $CLJS.N("mbql.stage","native","mbql.stage/native",359422194);KZ=new $CLJS.N("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);LZ=new $CLJS.N("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);MZ=new $CLJS.N("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);
$CLJS.NZ=new $CLJS.N("mbql","query","mbql/query",-1285688662);$CLJS.yZ=new $CLJS.N(null,"source-card","source-card",-1580820390);Cna=new $CLJS.N("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$CLJS.OZ=new $CLJS.N(null,"filters","filters",974726919);PZ=new $CLJS.N("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);QZ=new $CLJS.N("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.RZ=new $CLJS.N("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Ana=new $CLJS.N("stage","metadata","stage/metadata",1707239131);$CLJS.kL(GZ,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.JZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.WQ,$CLJS.Lb],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kl,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.ml,$CLJS.Lb],null)],null)],null));$CLJS.kL(AZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ml,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.EV],null)],null));
$CLJS.kL(LZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ml,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.EV],null)],null));$CLJS.kL(KZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ml,new $CLJS.k(null,1,[$CLJS.qk,1],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.LL],null)],null));
var SZ=$CLJS.Mm.h(function(a){a=$CLJS.C($CLJS.wZ(a));return $CLJS.m(a)?["Invalid :expression reference: no expression named ",$CLJS.aj.l($CLJS.G([$CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null))]))].join(""):null},function(a){a=$CLJS.C($CLJS.xZ(a));return $CLJS.m(a)?["Invalid :aggregation reference: no aggregation with uuid ",$CLJS.p.g($CLJS.Ju(a,new $CLJS.S(null,2,5,$CLJS.T,[1,2],null)))].join(""):null});
$CLJS.kL(MZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"Valid references for a single query stage",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return SZ(a)}],null),$CLJS.$f(SZ)],null));
$CLJS.kL($CLJS.RZ,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,11,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.DZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.XO,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.gZ],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RQ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.JL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.IP,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),AZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.PP,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.nW],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.zO,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),LZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.OZ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),KZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aS,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.mZ],
null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aQ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.hV],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yZ,new $CLJS.k(null,1,[$CLJS.Rj,!0],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.iV],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ie(a,$CLJS.nR)}],null),new $CLJS.S(null,3,5,$CLJS.T,
[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.$f($CLJS.Lm.h($CLJS.aQ,$CLJS.yZ))],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,MZ],null)],null));$CLJS.kL(BZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.aQ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.hV],null)],null)],null)],null));
$CLJS.kL(CZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.gu,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.yZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.iV],null)],null)],null)],null));$CLJS.kL(FZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,BZ],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,CZ],null)],null));
$CLJS.kL(HZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RZ],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.$f($CLJS.Mm.h($CLJS.aQ,$CLJS.yZ))],null)],null));$CLJS.kL(IZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Gt,$CLJS.JZ,$CLJS.DZ],null));
$CLJS.kL(Bna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,IZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.wk,$CLJS.vz],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.JZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,GZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.RZ],null)],null)],null)],null));
$CLJS.kL(QZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,IZ],null)],null),new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Nt,new $CLJS.k(null,1,[$CLJS.wk,$CLJS.vz],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.JZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,GZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.DZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,FZ],null)],null)],null)],null));$CLJS.kL(EZ,HZ);
$CLJS.kL(PZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,2,[$CLJS.dv,"Valid references for all query stages",$CLJS.fv,function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.ql);return zZ.g?zZ.g(a):zZ.call(null,a)}],null),$CLJS.$f(zZ)],null));
$CLJS.kL($CLJS.eZ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,QZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.lm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,EZ],null)],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,PZ],null)],null));
$CLJS.kL(Cna,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Wl,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.vz,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,$CLJS.NZ],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.OQ,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Bk,$CLJS.jV,$CLJS.kV],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.iZ,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.eZ],null)],null)],null),$CLJS.yna],null));