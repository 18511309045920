var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var xW,yW,Sla,AW,Tla,Ula,Vla,BW,zW;$CLJS.wW=function(a,b){return $CLJS.Sb(function(c,d){return $CLJS.m(a.g?a.g(d):a.call(null,d))?$CLJS.Id(d):null},null,b)};xW=function(a,b){$CLJS.J(a,0,null);$CLJS.J(a,1,null);$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);b=$CLJS.PH(b,$CLJS.PI)?$CLJS.KV:$CLJS.PH(b,$CLJS.YK)?$CLJS.oL:$CLJS.PH(b,$CLJS.TJ)?$CLJS.pL:null;return $CLJS.m(b)?$CLJS.qV(b,a):!0};
yW=function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);a=$CLJS.fj(function(d){return $CLJS.PH($CLJS.EL(d),$CLJS.mJ)},a);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,!1);b=$CLJS.M.h(b,!0);if($CLJS.ki.h($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.EL($CLJS.C(a));return $CLJS.Xf(function(d){$CLJS.J(d,0,null);$CLJS.J(d,1,null);$CLJS.J(d,2,null);
var e=$CLJS.J(d,3,null);return $CLJS.m(xW(d,c))?null:["Cannot add a ",$CLJS.p.g(e)," interval to a ",$CLJS.p.g(c)," expression"].join("")},b)};
Sla=function(a){return new $CLJS.S(null,4,5,$CLJS.T,[$CLJS.Lj,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.S(null,6,5,$CLJS.T,[$CLJS.xt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,zW],
null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wt,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,zW],null)],null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.fv,function(b){b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.ql);return["Invalid ",$CLJS.p.g(a)," clause: ",$CLJS.p.g(yW(b))].join("")}],null),$CLJS.$f(yW)],null)],null)};
AW=function(a){var b=$CLJS.T,c=Sla(a);a=new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.xt,new $CLJS.k(null,1,[$CLJS.dv,[$CLJS.p.g(a)," clause with numeric args"].join("")],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.fm,a],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.zL],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)],null)],
null);return new $CLJS.S(null,3,5,b,[$CLJS.Bk,c,a],null)};Tla=function(a){return $CLJS.Ve($CLJS.nV,$CLJS.rg.h(function(b){var c=$CLJS.EL(b),d=$CLJS.PH(c,$CLJS.NL);b=d?$CLJS.qV($CLJS.EV,b):d;return $CLJS.m(b)?$CLJS.Dl:c},a))};Ula=function(a){a=$CLJS.wW(function(b){return!$CLJS.PH(b,$CLJS.mJ)},$CLJS.rg.h($CLJS.EL,a));return $CLJS.PH(a,$CLJS.NL)?$CLJS.Kl:a};Vla=function(a){return $CLJS.m($CLJS.Xf(function(b){return $CLJS.PH($CLJS.EL(b),$CLJS.mJ)},a))?Ula(a):Tla(a)};
BW=new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.CW=new $CLJS.N("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);zW=new $CLJS.N("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.kL(new $CLJS.N("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Lj,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.At,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.IL,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.KL],null)],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.N(null,"intervals","intervals",2096054013),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.km,zW],
null)],null)],null),new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Ht,new $CLJS.k(null,1,[$CLJS.dv,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.A(a);a=$CLJS.C(b);b=$CLJS.D(b);var c=$CLJS.EL(a);return $CLJS.Wf(function(d){return xW(d,c)},b)}],null)],null));
$CLJS.kL(BW,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.wt,new $CLJS.k(null,1,[$CLJS.qk,2],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)],null));$CLJS.vV($CLJS.km,AW($CLJS.km));$CLJS.vV($CLJS.xu,AW($CLJS.xu));$CLJS.BV($CLJS.lm,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,BW],null)]));$CLJS.BV($CLJS.QK,$CLJS.G([$CLJS.xu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.kl,BW],null)]));
for(var DW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.km,$CLJS.xu,$CLJS.lm],null)),EW=null,FW=0,GW=0;;)if(GW<FW){var Wla=EW.$(null,GW);$CLJS.oV(Wla,$CLJS.CW);GW+=1}else{var HW=$CLJS.A(DW);if(HW){var IW=HW;if($CLJS.re(IW)){var JW=$CLJS.$c(IW),Xla=$CLJS.ad(IW),Yla=JW,Zla=$CLJS.E(JW);DW=Xla;EW=Yla;FW=Zla}else{var $la=$CLJS.C(IW);$CLJS.oV($la,$CLJS.CW);DW=$CLJS.D(IW);EW=null;FW=0}GW=0}else break}$CLJS.DL.o(null,$CLJS.CW,function(a){a=$CLJS.A(a);$CLJS.C(a);a=$CLJS.D(a);$CLJS.C(a);a=$CLJS.D(a);return Vla(a)});
$CLJS.zV($CLJS.uI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));$CLJS.oV($CLJS.uI,$CLJS.QL);
for(var KW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,$CLJS.$H,$CLJS.pJ],null)),LW=null,MW=0,NW=0;;)if(NW<MW){var ama=LW.$(null,NW);$CLJS.zV(ama,$CLJS.G([$CLJS.xu,$CLJS.LK,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));NW+=1}else{var OW=$CLJS.A(KW);if(OW){var PW=OW;if($CLJS.re(PW)){var QW=$CLJS.$c(PW),bma=$CLJS.ad(PW),cma=QW,dma=$CLJS.E(QW);KW=bma;LW=cma;MW=dma}else{var ema=$CLJS.C(PW);$CLJS.zV(ema,$CLJS.G([$CLJS.xu,$CLJS.LK,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));KW=$CLJS.D(PW);LW=null;MW=0}NW=0}else break}
for(var RW=$CLJS.A(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kI,$CLJS.HJ,$CLJS.WJ],null)),SW=null,TW=0,UW=0;;)if(UW<TW){var fma=SW.$(null,UW);$CLJS.zV(fma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));UW+=1}else{var VW=$CLJS.A(RW);if(VW){var WW=VW;if($CLJS.re(WW)){var XW=$CLJS.$c(WW),gma=$CLJS.ad(WW),hma=XW,ima=$CLJS.E(XW);RW=gma;SW=hma;TW=ima}else{var jma=$CLJS.C(WW);$CLJS.zV(jma,$CLJS.G([$CLJS.xu,$CLJS.il,new $CLJS.S(null,
2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));RW=$CLJS.D(WW);SW=null;TW=0}UW=0}else break}$CLJS.zV($CLJS.nI,$CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.gq,$CLJS.TL],null)],null)]));
$CLJS.DL.o(null,$CLJS.nI,function(a){$CLJS.J(a,0,null);$CLJS.J(a,1,null);var b=$CLJS.J(a,2,null);a=$CLJS.J(a,3,null);return $CLJS.PH($CLJS.EL(b),$CLJS.il)&&$CLJS.PH($CLJS.EL(a),$CLJS.il)?$CLJS.il:$CLJS.LK});