var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var W2,Fpa,X2,Y2,Gpa,Hpa,Z2,$2,Ipa,Jpa,Kpa,Lpa,e3,Npa,Opa,Ppa,Qpa,h3,i3,j3,k3,m3,Spa,n3,o3,Tpa,a3,c3;W2=function(a,b){return $CLJS.oe(a)?$CLJS.M.h(a,b):a};
Fpa=function(){return function(){function a(g,l,n){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.j?$CLJS.Wk.j(g,l,n):$CLJS.Wk.call(null,g,l,n),$CLJS.ln.j?$CLJS.ln.j(g,l,n):$CLJS.ln.call(null,g,l,n)],null)}function b(g,l){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.h?$CLJS.Wk.h(g,l):$CLJS.Wk.call(null,g,l),$CLJS.ln.h?$CLJS.ln.h(g,l):$CLJS.ln.call(null,g,l)],null)}function c(g){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.g?$CLJS.Wk.g(g):$CLJS.Wk.call(null,g),$CLJS.ln.g?$CLJS.ln.g(g):$CLJS.ln.call(null,
g)],null)}function d(){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Wk.A?$CLJS.Wk.A():$CLJS.Wk.call(null),$CLJS.ln.A?$CLJS.ln.A():$CLJS.ln.call(null)],null)}var e=null,f=function(){function g(n,q,u,v){var x=null;if(3<arguments.length){x=0;for(var y=Array(arguments.length-3);x<y.length;)y[x]=arguments[x+3],++x;x=new $CLJS.z(y,0,null)}return l.call(this,n,q,u,x)}function l(n,q,u,v){return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.R.R($CLJS.Wk,n,q,u,v),$CLJS.R.R($CLJS.ln,n,q,u,v)],null)}g.v=3;g.B=function(n){var q=
$CLJS.C(n);n=$CLJS.D(n);var u=$CLJS.C(n);n=$CLJS.D(n);var v=$CLJS.C(n);n=$CLJS.zd(n);return l(q,u,v,n)};g.l=l;return g}();e=function(g,l,n,q){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,g);case 2:return b.call(this,g,l);case 3:return a.call(this,g,l,n);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.z(v,0,null)}return f.l(g,l,n,u)}throw Error("Invalid arity: "+arguments.length);};e.v=
3;e.B=f.B;e.A=d;e.g=c;e.h=b;e.j=a;e.l=f.l;return e}()};X2=function(a,b){return $CLJS.le(a)||$CLJS.me(a)?$CLJS.M.h(a,b):$CLJS.ne(a)?$CLJS.M.h($CLJS.Mg(a),b):null};Y2=function(a,b){var c=$CLJS.A(b);for(a=$CLJS.A($CLJS.kg(a,b));;)if(a)c=$CLJS.D(c),a=$CLJS.D(a);else return c};Gpa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return null==e?d:$CLJS.U.j(d,f,e)},null==b?a:$CLJS.U.j(a,$CLJS.PP,b),$CLJS.vu(2,2,c))};
Hpa=function(){var a=$CLJS.Pp.h($CLJS.RZ,null),b=$CLJS.py(a,$CLJS.Naa,function(c){return $CLJS.Po(c,$CLJS.Lg)});return function(){function c(f,g,l){return(g=$CLJS.A(b.j?b.j(f,g,l):b.call(null,f,g,l)))?new $CLJS.k(null,3,[$CLJS.bm,a,$CLJS.ql,f,$CLJS.du,g],null):null}function d(f){return e.j(f,$CLJS.Lg,$CLJS.Lg)}var e=null;e=function(f,g,l){switch(arguments.length){case 1:return d.call(this,f);case 3:return c.call(this,f,g,l)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;return e}()};
Z2=function(a){return Hpa()(a,$CLJS.Lg,$CLJS.Lg)};$2=function(a,b,c,d){d=$CLJS.m(d)?d:$CLJS.Wr($CLJS.bm.g(a));if($CLJS.m(b)){var e=W2($CLJS.fv.g(b),c);$CLJS.m(e)?(e=$CLJS.$r(e,d),a=e.h?e.h(a,d):e.call(null,a,d)):a=null;return $CLJS.m(a)?a:W2($CLJS.dv.g(b),c)}return null};
Ipa=function(a,b){a=$CLJS.Qf(a);var c=$CLJS.M.h(a,$CLJS.bm),d=$CLJS.M.h(a,$CLJS.Wk);b=$CLJS.Qf(b);var e=$CLJS.M.j(b,$CLJS.du,$CLJS.nba),f=$CLJS.M.j(b,$CLJS.iba,!0),g=$CLJS.M.h(b,$CLJS.ov),l=$CLJS.M.j(b,$CLJS.mv,$CLJS.kv),n=$2(a,$CLJS.ls.g(c),g,b);if($CLJS.m(n))return n;n=$2(a,$CLJS.su.g(c),g,b);if($CLJS.m(n))return n;n=$2(a,e.g?e.g(d):e.call(null,d),g,b);if($CLJS.m(n))return n;n=$2(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),g,b);if($CLJS.m(n))return n;n=$2(a,$CLJS.ls.g(c),
l,b);if($CLJS.m(n))return n;n=$2(a,$CLJS.su.g(c),l,b);if($CLJS.m(n))return n;d=$2(a,e.g?e.g(d):e.call(null,d),l,b);if($CLJS.m(d))return d;d=$2(a,function(){var q=$CLJS.xp.g(c);return e.g?e.g(q):e.call(null,q)}(),l,b);if($CLJS.m(d))return d;g=$CLJS.m(f)?$2(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),g,b):f;return $CLJS.m(g)?g:$CLJS.m(f)?$2(a,e.g?e.g($CLJS.iv):e.call(null,$CLJS.iv),l,b):f};
Jpa=function(a,b,c){a=$CLJS.T;var d=$CLJS.oh.h;var e=$CLJS.ln.g(b);var f=$CLJS.Qf(b);var g=$CLJS.M.h(f,$CLJS.bm),l=$CLJS.Qf(c);f=$CLJS.M.h(l,$CLJS.ov);l=$CLJS.M.j(l,$CLJS.mv,$CLJS.kv);g=$CLJS.ls.g(g);f=W2($CLJS.lv.g(g),f);f=$CLJS.m(f)?f:W2($CLJS.lv.g(g),l);d=d.call($CLJS.oh,e,f);return new $CLJS.S(null,2,5,a,[d,Ipa(b,c)],null)};
Kpa=function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.ql);a=$CLJS.M.h(b,$CLJS.du);var d=$CLJS.Qf(null),e=$CLJS.M.j(d,$CLJS.jba,$CLJS.yk),f=$CLJS.M.j(d,$CLJS.lba,Jpa);return $CLJS.m(a)?$CLJS.Sb(function(g,l){var n=f.j?f.j(b,l,d):f.call(null,b,l,d),q=$CLJS.J(n,0,null);n=$CLJS.J(n,1,null);l=$CLJS.U.j(l,$CLJS.yk,n);l=e.g?e.g(l):e.call(null,l);return a3(g,c,q,l)},null,a):null};Lpa=function(a){return $CLJS.qg.h($CLJS.wZ(a),$CLJS.xZ(a))};
$CLJS.b3=function(a,b){return $CLJS.Pu($CLJS.a0(a)?a:new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,new $CLJS.k(null,2,[$CLJS.IG,$CLJS.p.g($CLJS.qL()),$CLJS.lk,$CLJS.EL(a)],null),a],null),new $CLJS.S(null,2,5,$CLJS.T,[1,$CLJS.OL],null),b)};$CLJS.d3=function(){return $CLJS.Km.h(c3,$CLJS.m_($CLJS.G([$CLJS.n_,$CLJS.kG,$CLJS.o_,function(a,b){return c3.g([$CLJS.p.g(a),"_",$CLJS.p.g(b)].join(""))}])))};
e3=function(a,b,c){var d=new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.Zt,null,$CLJS.pn,null],null),null),e=$CLJS.m(d.g?d.g(b):d.call(null,b))?$CLJS.Lu(2,c):$CLJS.Lu(1,c);b=$CLJS.m(d.g?d.g(b):d.call(null,b))?Y2(2,c):Y2(1,c);var f=$CLJS.J(b,0,null);return $CLJS.A(e)?$CLJS.q_.j(a,e,function(g){var l=$CLJS.U.j(g,f,null);return $CLJS.qe(g)?$CLJS.oh.j($CLJS.Lg,$CLJS.Nm.g($CLJS.Cb),l):$CLJS.oe(g)?$CLJS.aG($CLJS.Hb,l):l}):$CLJS.Cm.h(a,f)};
Npa=function(a){return $CLJS.Sb(function(b,c){var d=$CLJS.J(c,0,null);$CLJS.J(c,1,null);return e3(b,Mpa,d)},a,Lpa(a))};
Opa=function(a){for(var b=0;;){var c=$CLJS.Td($CLJS.iZ.g(a),b);a:{var d=c;for(var e=$CLJS.Lg;;){var f=$CLJS.C($CLJS.rg.h(Fpa(),$CLJS.ug($CLJS.Km.j(f3,$CLJS.C,$CLJS.ln),$CLJS.du.g(Z2(d)))));if($CLJS.m(f)){var g=f;f=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);var l=e3(d,f,g),n=$CLJS.YF($CLJS.YD);if($CLJS.m($CLJS.eG("metabase.lib.convert",n))){var q=$CLJS.PG.l($CLJS.G([$CLJS.TE,$CLJS.aj.l($CLJS.G([g]))])),u=$CLJS.PG,v=u.l,x=$CLJS.aj,y=x.l;var B=f;B=$CLJS.m(B)?B:Kpa(Z2(d));$CLJS.fG("metabase.lib.convert",n,
$CLJS.Z_("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([q,v.call(u,$CLJS.G([$CLJS.TE,y.call(x,$CLJS.G([B]))])),$CLJS.PG.l($CLJS.G([$CLJS.IE,$CLJS.$_($CLJS.C($CLJS.Bz(d,l)))]))])),null)}if($CLJS.F.h(l,d))break a;d=l;e=$CLJS.kf.h(e,new $CLJS.S(null,2,5,$CLJS.T,[f,g],null))}else break a}}d=Npa(d);if($CLJS.F.h(c,d)){if($CLJS.F.h(b,$CLJS.E($CLJS.iZ.g(a))-1))return a;b+=1}else a=$CLJS.Pm.R(a,$CLJS.iZ,$CLJS.U,b,d)}};
Ppa=function(a){var b=$CLJS.d3();return $CLJS.Om.h(function(c){return $CLJS.F.h($CLJS.yP.g(c),"__join")?$CLJS.Pm.j(c,$CLJS.yP,b):c},a)};Qpa=function(a){return"string"===typeof $CLJS.aQ.g(a)?$CLJS.Cm.h($CLJS.U.j(a,$CLJS.yZ,$CLJS.g0($CLJS.aQ.g(a))),$CLJS.aQ):a};h3=function(a,b){var c=$CLJS.M.j(a,b,g3);if($CLJS.F.h(c,g3))throw $CLJS.zj(["Unable to find ",$CLJS.aj.l($CLJS.G([b]))," in map."].join(""),new $CLJS.k(null,2,[Rpa,a,$CLJS.Zu,b],null));return c};
i3=function(){return $CLJS.Nm.g(function(a){var b=$CLJS.J(a,0,null);$CLJS.J(a,1,null);return $CLJS.xf(b)&&$CLJS.F.h($CLJS.qf(b),"lib")})};j3=function(a){return $CLJS.oh.j($CLJS.P,i3(),a)};k3=function(a){return $CLJS.Rf($CLJS.oh.j($CLJS.P,$CLJS.Km.h(i3(),$CLJS.Nm.g(function(b){var c=$CLJS.J(b,0,null);$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.lk)})),a))};
m3=function(a){var b=$CLJS.A(a);a=$CLJS.C(b);var c=$CLJS.D(b);b=$CLJS.C(c);c=$CLJS.D(c);var d=$CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[a],null),$CLJS.rg.g($CLJS.l3),c);a=$CLJS.F.h(a,$CLJS.$I)&&$CLJS.D(c)?$CLJS.kf.h($CLJS.ie(d),new $CLJS.k(null,1,[$CLJS.Pj,$CLJS.he(d)],null)):d;b=$CLJS.Rf(k3(b));return $CLJS.m(b)?new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qO,a,b],null):a};Spa=function(a){return $CLJS.oh.j($CLJS.Lg,$CLJS.Km.h($CLJS.rg.g(function(b){return $CLJS.cG(b,$CLJS.UG)}),$CLJS.rg.g($CLJS.l3)),$CLJS.e0.g(a))};
n3=function(a){a=$CLJS.Qf(a);a=$CLJS.M.h(a,$CLJS.iZ);a=$CLJS.C($CLJS.Sb(function(b,c){var d=$CLJS.J(b,0,null),e=$CLJS.J(b,1,null);b=$CLJS.T;var f=$CLJS.l3.g(c);d=$CLJS.m(d)?$CLJS.U.j(f,$CLJS.nR,d):f;d=$CLJS.m(e)?$CLJS.U.j(d,$CLJS.QP,Spa(e)):d;return new $CLJS.S(null,2,5,b,[d,$CLJS.pZ.g(c)],null)},null,a));return $CLJS.m($CLJS.WQ.g(a))?$CLJS.SN(a,new $CLJS.k(null,1,[$CLJS.WQ,$CLJS.xO],null)):a};
o3=function(a,b,c){var d=$CLJS.F.h($CLJS.E($CLJS.M.h(a,b)),1)?$CLJS.r_.j(a,b,$CLJS.Km.h($CLJS.l3,$CLJS.C)):a;a=1<$CLJS.E($CLJS.M.h(a,b))?$CLJS.r_.j(d,b,function(e){return $CLJS.oh.j(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Lj],null),$CLJS.rg.g($CLJS.l3),e)}):d;return $CLJS.SN(a,$CLJS.Pf([b,c]))};Tpa=function(a){var b=$CLJS.yZ.g(a);return $CLJS.m(b)?$CLJS.U.j($CLJS.Cm.h(a,$CLJS.yZ),$CLJS.aQ,["card__",$CLJS.p.g(b)].join("")):a};
a3=function a3(a,b,c,d){var f=$CLJS.A(c);c=$CLJS.C(f);var g=$CLJS.D(f),l=X2(b,c);f=$CLJS.m(a)?a:$CLJS.ne(b)?$CLJS.Lg:$CLJS.pe(b)?$CLJS.P:$CLJS.ae(b);return $CLJS.m($CLJS.m(c)?$CLJS.jv.g($CLJS.fe(f)):c)?a:$CLJS.m(c)?(b=X2(f,c),d=a3.D?a3.D(b,l,g,d):a3.call(null,b,l,g,d),$CLJS.Be(c)&&$CLJS.ne(f)&&c>$CLJS.E(f)&&(b=$CLJS.ng(c-$CLJS.E(f),null),b=$CLJS.qg.h(f,b),f=null==f||$CLJS.we(f)?b:$CLJS.oh.h($CLJS.ae(f),b)),null==f||$CLJS.me(f)?$CLJS.U.j(f,c,d):$CLJS.le(f)?$CLJS.kf.h(f,d):$CLJS.R.h($CLJS.X,$CLJS.U.j($CLJS.Mg(f),
c,d))):$CLJS.oe(a)?(c=new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.kba],null),a3.D?a3.D(f,b,c,d):a3.call(null,f,b,c,d)):$CLJS.m($CLJS.jv.g($CLJS.fe(f)))?$CLJS.kf.h(f,d):$CLJS.qe($CLJS.Rf(f))?f:$CLJS.K(new $CLJS.S(null,1,5,$CLJS.T,[d],null),new $CLJS.k(null,1,[$CLJS.jv,!0],null))};c3=function c3(a){switch(arguments.length){case 1:return c3.g(arguments[0]);case 2:return c3.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};
c3.g=function(a){return c3.h(a,60)};c3.h=function(a,b){if((new TextEncoder).encode(a).length<=b)return a;var c;a:for(c=($CLJS.lW.str(a,0)>>>0).toString(16);;)if(8>$CLJS.E(c))c=["0",$CLJS.p.g(c)].join("");else break a;b=new Uint8Array(b-9);b=(new TextEncoder).encodeInto(a,b);return[a.substring(0,b.read),"_",$CLJS.p.g(c)].join("")};c3.v=2;
var g3=new $CLJS.N("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550),Mpa=new $CLJS.N("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),p3=new $CLJS.N("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),q3=new $CLJS.N("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),Rpa=new $CLJS.N(null,"m","m",1632677161),r3=new $CLJS.N("metabase.lib.convert","aggregation",
"metabase.lib.convert/aggregation",108290790);var s3,t3,f3,Upa,Vpa,Wpa,Xpa,Ypa,Zpa,$pa,aqa,bqa,cqa;s3=$CLJS.P;t3=$CLJS.P;f3=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.OZ,null,$CLJS.zO,null,$CLJS.XO,null,$CLJS.RQ,null,$CLJS.IP,null,$CLJS.aS,null,$CLJS.PP,null],null),null);Upa=$CLJS.cj.g($CLJS.P);Vpa=$CLJS.cj.g($CLJS.P);Wpa=$CLJS.cj.g($CLJS.P);Xpa=$CLJS.cj.g($CLJS.P);Ypa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.EH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));
$CLJS.u3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3epMBQL"),$CLJS.DH,$CLJS.Pj,Ypa,Upa,Vpa,Wpa,Xpa);$CLJS.u3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){var b=$CLJS.he(a);var c=$CLJS.oe(b);a=c?$CLJS.ie(a):a;var d=$CLJS.A(a);a=$CLJS.C(d);d=$CLJS.D(d);b=$CLJS.gW($CLJS.oh.j(new $CLJS.S(null,2,5,$CLJS.T,[a,c?b:$CLJS.P],null),$CLJS.rg.g($CLJS.u3),d))}else b=a;return b});$CLJS.u3.o(null,$CLJS.NZ,function(a){return a});
$CLJS.u3.o(null,$CLJS.DZ,function(a){var b=$CLJS.u3.g($CLJS.PP.g(a)),c=$CLJS.Rf($CLJS.Om.h(function(g){var l=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.b3($CLJS.u3.g(g),l)},$CLJS.RQ.g(a))),d=t3;t3=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(g,l){$CLJS.J(l,0,null);l=$CLJS.J(l,1,null);l=$CLJS.Qf(l);l=$CLJS.M.h(l,$CLJS.IG);return new $CLJS.S(null,2,5,$CLJS.T,[g,l],null)}),b);try{var e=Gpa(Qpa(a),b,$CLJS.G([$CLJS.RQ,c])),f=$CLJS.Sb(function(g,l){return $CLJS.Gb($CLJS.M.h(g,l))?g:$CLJS.Pm.j(g,l,$CLJS.u3)},
e,$CLJS.Dm.l(f3,$CLJS.PP,$CLJS.G([$CLJS.RQ])));return $CLJS.m($CLJS.XO.g(f))?$CLJS.Pm.j(f,$CLJS.XO,Ppa):f}finally{t3=d}});$CLJS.u3.o(null,$CLJS.dZ,function(a){a=$CLJS.Pm.j($CLJS.Pm.j(a,$CLJS.lZ,$CLJS.u3),$CLJS.iZ,$CLJS.u3);var b=$CLJS.m($CLJS.zO.g(a))?$CLJS.Pm.j(a,$CLJS.zO,function(c){return $CLJS.xe(c)?$CLJS.Om.h($CLJS.u3,c):$CLJS.Oi.g(c)}):a;return $CLJS.Gb($CLJS.yP.g(a))?$CLJS.U.j(b,$CLJS.yP,"__join"):b});$CLJS.u3.o(null,$CLJS.xG,function(a){return $CLJS.Om.h($CLJS.u3,a)});
$CLJS.u3.o(null,$CLJS.zG,function(a){return $CLJS.m($CLJS.Wk.g(a))?Opa($CLJS.Pm.j($CLJS.f0(a),$CLJS.iZ,function(b){return $CLJS.Om.h($CLJS.u3,b)})):$CLJS.Ku(a,$CLJS.u3)});$CLJS.u3.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[a,b],null):new $CLJS.S(null,2,5,$CLJS.T,[b,a],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,a,b],null))});
$CLJS.u3.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=$CLJS.SN(a,new $CLJS.k(null,3,[$CLJS.uJ,$CLJS.DG,$CLJS.EP,$CLJS.Jl,$CLJS.iS,$CLJS.xL],null));var c=$CLJS.U.j;var d=$CLJS.lk.g(a);$CLJS.m(d)||(d=$CLJS.DG.g(a),d=$CLJS.m(d)?d:$CLJS.EL(b));a=c.call($CLJS.U,a,$CLJS.lk,d);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,a,b],null))});
$CLJS.u3.o(null,$CLJS.$I,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);a=$CLJS.Pj.g(c);b=new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$I,$CLJS.Cm.h(c,$CLJS.Pj),$CLJS.Om.h($CLJS.u3,b)],null);b=$CLJS.gW(b);return null!=a?$CLJS.kf.h(b,$CLJS.u3.g(a)):b});$CLJS.u3.o(null,$CLJS.AG,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,c],null))});
$CLJS.u3.o(null,$CLJS.PP,function(a){var b=$CLJS.J(a,0,null),c=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);return $CLJS.gW(new $CLJS.S(null,3,5,$CLJS.T,[b,a,h3(t3,c)],null))});$CLJS.u3.o(null,$CLJS.qO,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=$CLJS.u3.g(b);var c=$CLJS.A(b);b=$CLJS.C(c);var d=$CLJS.D(c);c=$CLJS.C(d);d=$CLJS.D(d);return $CLJS.oh.h(new $CLJS.S(null,2,5,$CLJS.T,[b,$CLJS.en.l($CLJS.G([c,a]))],null),d)});Zpa=$CLJS.cj.g($CLJS.P);$pa=$CLJS.cj.g($CLJS.P);aqa=$CLJS.cj.g($CLJS.P);
bqa=$CLJS.cj.g($CLJS.P);cqa=$CLJS.M.j(new $CLJS.k(null,1,[$CLJS.Qk,$CLJS.EH],null),$CLJS.Qk,$CLJS.lj.A?$CLJS.lj.A():$CLJS.lj.call(null));$CLJS.l3=new $CLJS.vj($CLJS.Ui.h("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.DH,$CLJS.Pj,cqa,Zpa,$pa,aqa,bqa);
$CLJS.l3.o(null,$CLJS.Pj,function(a){if($CLJS.qe(a)&&$CLJS.C(a)instanceof $CLJS.N){a=$CLJS.A(a);var b=$CLJS.C(a),c=$CLJS.D(a);a=$CLJS.C(c);var d=$CLJS.D(c);if($CLJS.oe(a)){c=$CLJS.oh.h;b=new $CLJS.S(null,1,5,$CLJS.T,[b],null);var e=$CLJS.qg.h;d=$CLJS.rg.h($CLJS.l3,d);a=k3(a);a=c.call($CLJS.oh,b,e.call($CLJS.qg,d,$CLJS.m(a)?new $CLJS.S(null,1,5,$CLJS.T,[a],null):null))}else a=$CLJS.oh.h(new $CLJS.S(null,1,5,$CLJS.T,[b],null),$CLJS.rg.h($CLJS.l3,$CLJS.nf(a,d)))}else a=$CLJS.oe(a)?$CLJS.Ku(j3(a),$CLJS.l3):
a;return a});for(var v3=$CLJS.A(new $CLJS.S(null,2,5,$CLJS.T,[r3,q3],null)),w3=null,x3=0,y3=0;;)if(y3<x3){var dqa=w3.$(null,y3);$CLJS.oV(dqa,p3);y3+=1}else{var z3=$CLJS.A(v3);if(z3){var A3=z3;if($CLJS.re(A3)){var B3=$CLJS.$c(A3),eqa=$CLJS.ad(A3),fqa=B3,gqa=$CLJS.E(B3);v3=eqa;w3=fqa;x3=gqa}else{var hqa=$CLJS.C(A3);$CLJS.oV(hqa,p3);v3=$CLJS.D(A3);w3=null;x3=0}y3=0}else break}
for(var C3=$CLJS.A(new $CLJS.S(null,12,5,$CLJS.T,[$CLJS.lD,$CLJS.DJ,$CLJS.fK,$CLJS.NI,$CLJS.Xl,$CLJS.vK,$CLJS.qk,$CLJS.nJ,$CLJS.ZJ,$CLJS.AK,$CLJS.tK,$CLJS.OK],null)),D3=null,E3=0,F3=0;;)if(F3<E3){var iqa=D3.$(null,F3);$CLJS.oV(iqa,r3);F3+=1}else{var G3=$CLJS.A(C3);if(G3){var H3=G3;if($CLJS.re(H3)){var I3=$CLJS.$c(H3),jqa=$CLJS.ad(H3),kqa=I3,lqa=$CLJS.E(I3);C3=jqa;D3=kqa;E3=lqa}else{var mqa=$CLJS.C(H3);$CLJS.oV(mqa,r3);C3=$CLJS.D(H3);D3=null;E3=0}F3=0}else break}
for(var J3=$CLJS.A($CLJS.Hg([$CLJS.km,$CLJS.xu,$CLJS.lm,$CLJS.QK,$CLJS.$I,$CLJS.RJ,$CLJS.uI,$CLJS.SK,$CLJS.$H,$CLJS.pJ,$CLJS.kI,$CLJS.HJ,$CLJS.WJ,$CLJS.nI,$CLJS.jJ,$CLJS.sJ,$CLJS.ZD,$CLJS.GI,$CLJS.EJ,$CLJS.vI,$CLJS.dK,$CLJS.FI,$CLJS.ZH,$CLJS.bK,$CLJS.xK,$CLJS.hK,$CLJS.EI,$CLJS.TK,$CLJS.eK,$CLJS.MK,$CLJS.pI,$CLJS.bI,$CLJS.WK,$CLJS.$W,$CLJS.PJ,$CLJS.jD,$CLJS.CK,$CLJS.HK,$CLJS.ZK,$CLJS.Ru,$CLJS.$u],!0)),K3=null,L3=0,M3=0;;)if(M3<L3){var nqa=K3.$(null,M3);$CLJS.oV(nqa,q3);M3+=1}else{var N3=$CLJS.A(J3);
if(N3){var O3=N3;if($CLJS.re(O3)){var P3=$CLJS.$c(O3),oqa=$CLJS.ad(O3),pqa=P3,qqa=$CLJS.E(P3);J3=oqa;K3=pqa;L3=qqa}else{var rqa=$CLJS.C(O3);$CLJS.oV(rqa,q3);J3=$CLJS.D(O3);K3=null;L3=0}M3=0}else break}$CLJS.l3.o(null,p3,function(a){return m3(a)});$CLJS.l3.o(null,$CLJS.zG,function(a){return $CLJS.oh.j($CLJS.P,$CLJS.Km.h(i3(),$CLJS.rg.g(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[c,$CLJS.l3.g(b)],null)})),a)});
$CLJS.l3.o(null,$CLJS.PP,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null),c=$CLJS.J(a,2,null);return $CLJS.oe(b)?(a=k3(b),c=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.PP,h3(s3,c)],null),$CLJS.m(a)?$CLJS.kf.h(c,a):c):a});$CLJS.l3.o(null,$CLJS.xG,function(a){return $CLJS.Om.h($CLJS.l3,a)});
$CLJS.l3.o(null,$CLJS.GQ,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);a=null==b||$CLJS.oe(b)?new $CLJS.S(null,2,5,$CLJS.T,[b,a],null):new $CLJS.S(null,2,5,$CLJS.T,[a,b],null);b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GQ,$CLJS.l3.g(a),k3(b)],null)});
$CLJS.l3.o(null,$CLJS.ql,function(a){$CLJS.J(a,0,null);var b=$CLJS.J(a,1,null);a=$CLJS.J(a,2,null);b=k3($CLJS.SN(b,new $CLJS.k(null,3,[$CLJS.DG,$CLJS.uJ,$CLJS.Jl,$CLJS.EP,$CLJS.xL,$CLJS.iS],null)));return new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ql,a,b],null)});
$CLJS.l3.o(null,$CLJS.dZ,function(a){var b=j3(a);a=0==$CLJS.yP.g(a).lastIndexOf("__join",0)?$CLJS.Cm.h(b,$CLJS.yP):b;return $CLJS.en.l($CLJS.G([$CLJS.Ku($CLJS.Cm.l(a,$CLJS.iZ,$CLJS.G([$CLJS.lZ])),$CLJS.l3),o3($CLJS.li(a,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.lZ],null)),$CLJS.lZ,$CLJS.cS),n3(a)]))});
$CLJS.l3.o(null,$CLJS.DZ,function(a){var b=s3;s3=$CLJS.oh.j($CLJS.P,$CLJS.Go(function(c,d){$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);d=$CLJS.Qf(d);d=$CLJS.M.h(d,$CLJS.IG);return new $CLJS.S(null,2,5,$CLJS.T,[d,c],null)}),$CLJS.PP.g(a));try{return $CLJS.Sb(function(c,d){return $CLJS.r_.j(c,d,$CLJS.l3)},o3($CLJS.r_.j($CLJS.r_.j(Tpa(j3(a)),$CLJS.PP,function(c){return $CLJS.Om.h(m3,c)}),$CLJS.RQ,function(c){return $CLJS.oh.h($CLJS.P,function(){return function f(e){return new $CLJS.yf(null,function(){for(;;){var g=
$CLJS.A(e);if(g){if($CLJS.re(g)){var l=$CLJS.$c(g),n=$CLJS.E(l),q=$CLJS.Bf(n);a:for(var u=0;;)if(u<n){var v=$CLJS.be(l,u),x=$CLJS.l3.g(v);v=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.b0(v),$CLJS.F.h($CLJS.ql,$CLJS.C(x))?$CLJS.Zd(x):x],null);q.add(v);u+=1}else{l=!0;break a}return l?$CLJS.Ef($CLJS.Gf(q),f($CLJS.ad(g))):$CLJS.Ef($CLJS.Gf(q),null)}q=$CLJS.C(g);l=$CLJS.l3.g(q);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.b0(q),$CLJS.F.h($CLJS.ql,$CLJS.C(l))?$CLJS.Zd(l):l],null),f($CLJS.zd(g)))}return null}},
null,null)}(c)}())}),$CLJS.OZ,$CLJS.LO),$CLJS.Dm.l(f3,$CLJS.PP,$CLJS.G([$CLJS.OZ,$CLJS.RQ])))}finally{s3=b}});$CLJS.l3.o(null,$CLJS.JZ,function(a){return $CLJS.Ku(j3(a),$CLJS.l3)});
$CLJS.l3.o(null,$CLJS.NZ,function(a){var b=j3(a),c=$CLJS.QO.g(b),d=n3(b),e=$CLJS.F.h($CLJS.vz.g($CLJS.$d($CLJS.iZ.g(a))),$CLJS.JZ)?$CLJS.WQ:$CLJS.xO;a=$CLJS.en.l;b=$CLJS.Ku($CLJS.Cm.l(b,$CLJS.iZ,$CLJS.G([$CLJS.QO])),$CLJS.l3);d=$CLJS.Pf([$CLJS.Wk,e,e,d]);c=$CLJS.A(c)?$CLJS.U.j(d,$CLJS.QO,c):d;return a.call($CLJS.en,$CLJS.G([b,c]))});