var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");require("./clojure.set.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./schema.core.js");
'use strict';var RN,xha,UN,VN,WN,XN,ZN,$N,aO,bO,eO,fO,gO,hO,iO,jO,kO,mO,nO,yha,zha,Aha,Bha,Cha,Dha,pO,Eha,Fha,Gha,Hha,rO,sO,tO,uO,Iha,vO,wO,Jha,Kha,Lha,yO,AO,Mha,BO,CO,Nha,Oha,DO,EO,Pha,FO,GO,IO,JO,Qha,KO,Rha,MO,Sha,Tha,NO,PO,Uha,Vha,RO,SO,Wha,Xha,VO,Yha,WO,Zha,ZO,$O,aP,$ha,aia,bia,bP,cP,dP,cia,dia,eP,eia,fia,gia,fP,hP,iP,hia,iia,jia,kia,lia,jP,kP,lP,mia,mP,nia,oia,pia,nP,qia,ria,sia,tia,oP,uia,pP,via,rP,sP,tP,uP,vP,wia,zP,xia,yia,AP,BP,zia,CP,DP,Aia,FP,GP,HP,JP,KP,Bia,LP,Cia,Dia,Eia,MP,NP,OP,RP,
Fia,TP,UP,VP,WP,Gia,Hia,Iia,YP,ZP,Jia,$P,Kia,Lia,Mia,Nia,dQ,Oia,Pia,eQ,fQ,gQ,Qia,hQ,iQ,jQ,Ria,kQ,Sia,Tia,lQ,mQ,nQ,pQ,qQ,Uia,sQ,tQ,uQ,vQ,Via,xQ,yQ,Wia,AQ,BQ,CQ,DQ,EQ,FQ,Xia,Yia,Zia,$ia,aja,HQ,bja,cja,IQ,JQ,KQ,dja,LQ,eja,MQ,fja,gja,NQ,hja,ija,jja,kja,PQ,QQ,lja,mja,nja,TQ,oja,UQ,pja,VQ,qja,XQ,YQ,rja,ZQ,$Q,aR,bR,cR,sja,dR,tja,eR,uja,vja,fR,gR,hR,wja,xja,iR,yja,zja,jR,Aja,Bja,Cja,Dja,mR,Eja,Fja,Gja,Hja,Ija,Jja,Kja,qR,rR,sR,tR,uR,Lja,vR,wR,xR,yR,Mja,Nja,Oja,Pja,zR,AR,BR,Qja,CR,DR,Rja,Sja,ER,Tja,Uja,FR,
Vja,HR,IR,Wja,JR,Xja,LR,MR,Yja,NR,OR,Zja,PR,$ja,QR,aka,SR,TR,UR,VR,bka,WR,cka,YR,dka,ZR,$R,bS,eka,fka,gka,dS,eS,hka,fS,ika,jka,kka,gS,lka,hS,mka,jS,kS,nka,oka,oS,pS,pka,qS,rS,sS,tS,qka,rka;RN=function(a,b){if(null!=a&&null!=a.Td)a=a.Td(a,b);else{var c=RN[$CLJS.Na(null==a?null:a)];if(null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else if(c=RN._,null!=c)a=c.h?c.h(a,b):c.call(null,a,b);else throw $CLJS.Pb("CoreSpec.checker",a);}return a};
xha=function(a,b){var c=new $CLJS.k(null,3,[$CLJS.oM,a,$CLJS.rM,!1,$CLJS.pM,$CLJS.cj.g($CLJS.P)],null);return a.h?a.h(b,c):a.call(null,b,c)};$CLJS.SN=function(a,b){return $CLJS.Sb(function(c,d){var e=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.Ie(a,e)?$CLJS.U.j(c,d,$CLJS.M.h(a,e)):c},$CLJS.R.j($CLJS.Cm,a,$CLJS.gi(b)),b)};$CLJS.TN=function(a,b){return new $CLJS.jN(a,!1,b,null,null,null)};UN=function(a){return new $CLJS.aN(a,null,null,null)};
VN=function(a,b,c,d){this.Nc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};WN=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return WN.l(0<b.length?new $CLJS.z(b.slice(0),0,null):null)};XN=function(a,b,c,d,e){this.kc=a;this.pc=b;this.F=c;this.m=d;this.G=e;this.C=2230716170;this.K=139264};ZN=function(a,b,c){return YN.l($CLJS.G([a,b,$CLJS.ag(!0),c]))};$N=function(a,b,c,d){this.zc=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};
aO=function(a){if(!(null!=a?a.C&32768||$CLJS.t===a.Zf||(a.C?0:$CLJS.Nb($CLJS.Kz,a)):$CLJS.Nb($CLJS.Kz,a)))throw Error($CLJS.XL("Not an IDeref: %s",$CLJS.G([a])));return new $N(a,null,null,null)};bO=function(a,b,c,d){this.X=a;this.F=b;this.m=c;this.G=d;this.C=2230716170;this.K=139264};$CLJS.cO=function(a){return new bO(a,null,null,null)};$CLJS.dO=function(a){return $CLJS.Km.h($CLJS.kM,xha(function(b,c){return RN($CLJS.PM(b),c)},a))};
eO=function(a,b){if($CLJS.qe(b)){var c=$CLJS.J(b,0,null),d=$CLJS.J(b,1,null);switch(c instanceof $CLJS.N?c.S:null){case "optional":return new $CLJS.jN($CLJS.cO(d),!0,a,null,null,null);case "rest":return $CLJS.XM(d,a);default:return $CLJS.TN(b,a)}}else return $CLJS.TN(b,a)};
fO=function(a,b){return $CLJS.Mg($CLJS.nf($CLJS.TN($CLJS.TM(a),a),function(){return function e(d){return new $CLJS.yf(null,function(){for(;;){var f=$CLJS.A(d);if(f){if($CLJS.re(f)){var g=$CLJS.$c(f),l=$CLJS.E(g),n=$CLJS.Bf(l);a:for(var q=0;;)if(q<l){var u=$CLJS.be(g,q),v=$CLJS.J(u,0,null);u=$CLJS.J(u,1,null);v=eO(v,u);n.add(v);q+=1}else{g=!0;break a}return g?$CLJS.Ef($CLJS.Gf(n),e($CLJS.ad(f))):$CLJS.Ef($CLJS.Gf(n),null)}g=$CLJS.C(f);n=$CLJS.J(g,0,null);g=$CLJS.J(g,1,null);return $CLJS.nf(eO(n,g),
e($CLJS.zd(f)))}return null}},null,null)}($CLJS.vu(2,2,b))}()))};gO=function(a,b){var c=$CLJS.ne(b);return c?(c=$CLJS.C(b)instanceof $CLJS.N)?$CLJS.ke(a)?(b=$CLJS.C(b),a=$CLJS.si(a),a.g?a.g(b):a.call(null,b)):$CLJS.F.h(a,$CLJS.C(b)):c:c};
hO=function(a){return $CLJS.XM($CLJS.R.h(YN,$CLJS.Ve($CLJS.qg,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(gO,u),q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,
1,null);return $CLJS.nf(new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bg(gO,l),f],null),d($CLJS.zd(e)))}return null}},null,null)}(a)}())),["Must be a valid instance of one of these clauses: ",$CLJS.bv.h(", ",$CLJS.rg.h($CLJS.C,a))].join(""))};iO=function(a){return $CLJS.ZM(a,$CLJS.A,"Non-empty")};jO=function(a){return $CLJS.A(a)?$CLJS.R.h($CLJS.Qu,a):!0};kO=function(a){return"string"===typeof a?$CLJS.ki.h(Date.parse(a),NaN):null};
$CLJS.tM.prototype.Td=$CLJS.Ma(56,function(){var a=this;return function(b){var c=a.na.g?a.na.g(b):a.na.call(null,b);return $CLJS.m(c)?c:b}});
$CLJS.yM.prototype.Td=$CLJS.Ma(55,function(a,b){var c=this,d=this,e=$CLJS.Sb(function(f,g){return $CLJS.Hga(g,b,f)},function(f){return $CLJS.iM($CLJS.dM(d,f,new $CLJS.Yi(function(){var g=$CLJS.ZL(f);return c.bc.g?c.bc.g(g):c.bc.call(null,g)}),null))},$CLJS.lf(c.options));return $CLJS.m(c.Ub)?function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;g=e.g?e.g(f):e.call(null,f);if($CLJS.jM(g))return g;f=$CLJS.m($CLJS.rM.g(b))?g:f;f=c.Ub.g?c.Ub.g(f):c.Ub.call(null,f);return $CLJS.m(f)?
f:g}:function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);return $CLJS.m(g)?g:e.g?e.g(f):e.call(null,f)}});
$CLJS.GM.prototype.Td=$CLJS.Ma(54,function(a,b){var c=this,d=$CLJS.m($CLJS.rM.g(b))?c.sc:function(){return null},e=$CLJS.EM(c.elements,b,function(f,g){return g});return function(f){var g=c.na.g?c.na.g(f):c.na.call(null,f);if($CLJS.m(g))return g;var l=$CLJS.cj.g($CLJS.Lg);g=e.h?e.h(l,f):e.call(null,l,f);l=$CLJS.r(l);var n=(n=$CLJS.A(g))?n:$CLJS.Xf($CLJS.jM,l);return $CLJS.m(n)?$CLJS.iM(c.dc.j?c.dc.j(f,l,g):c.dc.call(null,f,l,g)):d.g?d.g(l):d.call(null,l)}});$CLJS.lO={};mO={};nO={};$CLJS.h=VN.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "vs":return this.Nc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.EnumSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.sN,this.Nc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.sN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=2002701468^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.Nc,b.Nc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.sN,null],null),null),b)?$CLJS.Cm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new VN(this.Nc,this.F,$CLJS.Rf($CLJS.Cm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "vs":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.sN,b):$CLJS.O.call(null,$CLJS.sN,b))?new VN(c,this.F,this.m,null):new VN(this.Nc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.sN,this.Nc)],null),this.m))};$CLJS.h.O=function(a,b){return new VN(this.Nc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){var a=this;return $CLJS.uM($CLJS.mM(this,function(b){return $CLJS.Ie(a.Nc,b)},function(b){return new $CLJS.gf(null,a.Nc,new $CLJS.gf(null,b,null,1,null),2,null)}))};$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.qha,this.Nc)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};WN.l=function(a){return new VN($CLJS.si(a),null,null,null)};WN.v=0;
WN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=XN.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":return this.kc;case "error-symbol":return this.pc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.ConditionalSchema{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uN,this.kc],null),new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.tN,this.pc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,2,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.uN,$CLJS.tN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};
$CLJS.h.ea=function(){return 2+$CLJS.E(this.m)};$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1418435858^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.kc,b.kc)&&$CLJS.F.h(this.pc,b.pc)&&$CLJS.F.h(this.m,b.m)};
$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.uN,null,$CLJS.tN,null],null),null),b)?$CLJS.Cm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new XN(this.kc,this.pc,this.F,$CLJS.Rf($CLJS.Cm.h(this.m,b)),null)};$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "preds-and-schemas":case "error-symbol":return!0;default:return $CLJS.Ie(this.m,b)}};
$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.uN,b):$CLJS.O.call(null,$CLJS.uN,b))?new XN(c,this.pc,this.F,this.m,null):$CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.tN,b):$CLJS.O.call(null,$CLJS.tN,b))?new XN(this.kc,c,this.F,this.m,null):new XN(this.kc,this.pc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.Je($CLJS.uN,this.kc),new $CLJS.Je($CLJS.tN,this.pc)],null),this.m))};
$CLJS.h.O=function(a,b){return new XN(this.kc,this.pc,b,this.m,this.G)};$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};
$CLJS.h.Db=function(){var a=this;return $CLJS.zM($CLJS.OM,function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null);q=$CLJS.J(q,1,null);l.add(new $CLJS.k(null,2,[$CLJS.vM,u,$CLJS.bm,q],null));n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,
null);return $CLJS.nf(new $CLJS.k(null,2,[$CLJS.vM,l,$CLJS.bm,f],null),d($CLJS.zd(e)))}return null}},null,null)}(a.kc)}(),function(b){var c=a.pc;c=$CLJS.m(c)?c:$CLJS.F.h(1,$CLJS.E(a.kc))?$CLJS.Ui.g($CLJS.$L($CLJS.C($CLJS.C(a.kc)))):$CLJS.Tga;return new $CLJS.gf(null,c,new $CLJS.gf(null,b,null,1,null),2,null)})};
$CLJS.h.Fa=function(){return $CLJS.nf($CLJS.Uga,$CLJS.qg.h($CLJS.sg(function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Ui.g($CLJS.$L(b)),$CLJS.QM(a)],null)},$CLJS.G([this.kc])),$CLJS.m(this.pc)?new $CLJS.S(null,1,5,$CLJS.T,[this.pc],null):null))};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
var YN=function YN(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return YN.l(0<c.length?new $CLJS.z(c.slice(0),0,null):null)};
YN.l=function(a){if(!$CLJS.A(a)||!($CLJS.Zf($CLJS.E(a))||$CLJS.$d(a)instanceof $CLJS.w))throw Error($CLJS.XL("Expected even, nonzero number of args (with optional trailing symbol); got %s",$CLJS.G([$CLJS.E(a)])));return new XN($CLJS.Mg(function(){return function d(c){return new $CLJS.yf(null,function(){for(;;){var e=$CLJS.A(c);if(e){if($CLJS.re(e)){var f=$CLJS.$c(e),g=$CLJS.E(f),l=$CLJS.Bf(g);a:for(var n=0;;)if(n<g){var q=$CLJS.be(f,n),u=$CLJS.J(q,0,null),v=$CLJS.J(q,1,null);q=l;if(!$CLJS.ze(u))throw Error($CLJS.YL(["Conditional predicate ",
$CLJS.p.g(u)," must be a function"].join("")));u=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(u,$CLJS.Tu)?$CLJS.ag(!0):u,v],null);q.add(u);n+=1}else{f=!0;break a}return f?$CLJS.Ef($CLJS.Gf(l),d($CLJS.ad(e))):$CLJS.Ef($CLJS.Gf(l),null)}f=$CLJS.C(e);l=$CLJS.J(f,0,null);f=$CLJS.J(f,1,null);if(!$CLJS.ze(l))throw Error($CLJS.YL(["Conditional predicate ",$CLJS.p.g(l)," must be a function"].join("")));l=new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.F.h(l,$CLJS.Tu)?$CLJS.ag(!0):l,f],null);return $CLJS.nf(l,d($CLJS.zd(e)))}return null}},
null,null)}($CLJS.vu(2,2,a))}()),$CLJS.Zf($CLJS.E(a))?null:$CLJS.$d(a),null,null,null)};YN.v=0;YN.B=function(a){return this.l($CLJS.A(a))};$CLJS.h=$N.prototype;$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return this.zc;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Recursive{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.wN,this.zc],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.wN],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=1521498755^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.zc,b.zc)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.wN,null],null),null),b)?$CLJS.Cm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new $N(this.zc,this.F,$CLJS.Rf($CLJS.Cm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "derefable":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.wN,b):$CLJS.O.call(null,$CLJS.wN,b))?new $N(c,this.F,this.m,null):new $N(this.zc,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.wN,this.zc)],null),this.m))};$CLJS.h.O=function(a,b){return new $N(this.zc,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.AM($CLJS.OM,new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.k(null,1,[$CLJS.bm,$CLJS.r(this.zc)],null)],null))};
$CLJS.h.Fa=function(){if(this.zc instanceof $CLJS.ud){var a=$CLJS.fe(this.zc);var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.Dk);b=$CLJS.M.h(b,$CLJS.V);a=$CLJS.Ui.g([$CLJS.p.g(a),"/",$CLJS.p.g(b)].join(""));a=new $CLJS.gf(null,$CLJS.Il,new $CLJS.gf(null,a,null,1,null),2,null)}else a=$CLJS.rha;return new $CLJS.gf(null,$CLJS.hha,new $CLJS.gf(null,a,null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};$CLJS.h=bO.prototype;
$CLJS.h.ka=function(a,b){return this.T(null,b,null)};$CLJS.h.T=function(a,b,c){switch(b instanceof $CLJS.N?b.S:null){case "schema":return this.X;default:return $CLJS.M.j(this.m,b,c)}};$CLJS.h.xa=function(a,b,c){return $CLJS.Sb(function(d,e){var f=$CLJS.J(e,0,null);e=$CLJS.J(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.h.W=function(a,b,c){return $CLJS.Ii(b,function(d){return $CLJS.Ii(b,$CLJS.Ni,""," ","",c,d)},"#schema.core.Maybe{",", ","}",c,$CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.bm,this.X],null)],null),this.m))};$CLJS.h.oa=function(){return new $CLJS.eh(this,1,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.bm],null),$CLJS.m(this.m)?$CLJS.fd(this.m):$CLJS.Tf())};$CLJS.h.N=function(){return this.F};$CLJS.h.ea=function(){return 1+$CLJS.E(this.m)};
$CLJS.h.ca=function(){var a=this.G;return null!=a?a:this.G=a=-805411239^$CLJS.Ed(this)};$CLJS.h.U=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.h(this.X,b.X)&&$CLJS.F.h(this.m,b.m)};$CLJS.h.ya=function(a,b){return $CLJS.Ie(new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.bm,null],null),null),b)?$CLJS.Cm.h($CLJS.Dc($CLJS.oh.h($CLJS.P,this),this.F),b):new bO(this.X,this.F,$CLJS.Rf($CLJS.Cm.h(this.m,b)),null)};
$CLJS.h.sa=function(a,b){switch(b instanceof $CLJS.N?b.S:null){case "schema":return!0;default:return $CLJS.Ie(this.m,b)}};$CLJS.h.Z=function(a,b,c){return $CLJS.m($CLJS.O.h?$CLJS.O.h($CLJS.bm,b):$CLJS.O.call(null,$CLJS.bm,b))?new bO(c,this.F,this.m,null):new bO(this.X,this.F,$CLJS.U.j(this.m,b,c),null)};$CLJS.h.ba=function(){return $CLJS.A($CLJS.qg.h(new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.Je($CLJS.bm,this.X)],null),this.m))};$CLJS.h.O=function(a,b){return new bO(this.X,b,this.m,this.G)};
$CLJS.h.ga=function(a,b){return $CLJS.qe(b)?this.Z(null,$CLJS.be(b,0),$CLJS.be(b,1)):$CLJS.Sb($CLJS.ec,this,b)};$CLJS.h.Db=function(){return $CLJS.AM($CLJS.OM,new $CLJS.S(null,2,5,$CLJS.T,[new $CLJS.k(null,2,[$CLJS.vM,$CLJS.Cb,$CLJS.bm,$CLJS.TM(null)],null),new $CLJS.k(null,1,[$CLJS.bm,this.X],null)],null))};$CLJS.h.Fa=function(){return new $CLJS.gf(null,$CLJS.iha,new $CLJS.gf(null,$CLJS.QM(this.X),null,1,null),2,null)};$CLJS.h.ma=$CLJS.t;$CLJS.h.W=function(a,b){return $CLJS.Qc(b,this.Fa(null))};
yha=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);zha=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);$CLJS.oO=new $CLJS.N(null,"right-join","right-join",-56349359);Aha=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);Bha=new $CLJS.N(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
Cha=new $CLJS.N(null,"query-hash","query-hash",-1524484965);Dha=new $CLJS.N(null,"from","from",1815293044);pO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Eha=new $CLJS.N(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);Fha=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Gha=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);
$CLJS.qO=new $CLJS.N(null,"aggregation-options","aggregation-options",-1904917550);Hha=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);rO=new $CLJS.N("location","zip_code","location/zip_code",1641155222);sO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);tO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);uO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);Iha=new $CLJS.N(null,"lon-max","lon-max",1590224717);
vO=new $CLJS.w(null,"stddev","stddev",775056588,null);wO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.xO=new $CLJS.N(null,"query","query",-1288509510);Jha=new $CLJS.N(null,"lat-field","lat-field",-830652957);Kha=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);Lha=new $CLJS.w(null,"FieldOptions","FieldOptions",-1536320891,null);yO=new $CLJS.N("location","country","location/country",1666636202);
$CLJS.zO=new $CLJS.N(null,"fields","fields",-1932066230);AO=new $CLJS.w("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",-1908264889,null);Mha=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);BO=new $CLJS.N(null,"unary","unary",-989314568);CO=new $CLJS.w(null,"ceil","ceil",-184398425,null);Nha=new $CLJS.N(null,"lon-min","lon-min",-787291357);Oha=new $CLJS.N(null,"match","match",1220059550);
DO=new $CLJS.w(null,"count-where","count-where",2025939247,null);EO=new $CLJS.N(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Pha=new $CLJS.N(null,"embedded-question","embedded-question",-2146473954);FO=new $CLJS.w(null,"sum","sum",1777518341,null);GO=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.HO=new $CLJS.N("date","range","date/range",1647265776);IO=new $CLJS.w(null,"between","between",-1523336493,null);
JO=new $CLJS.N(null,"clause-form","clause-form",1820463737);Qha=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);KO=new $CLJS.w(null,"field","field",338095027,null);Rha=new $CLJS.N(null,"segment-id","segment-id",1810133590);$CLJS.LO=new $CLJS.N(null,"filter","filter",-948537934);MO=new $CLJS.w(null,"not-null","not-null",313812992,null);Sha=new $CLJS.N(null,"template-tag","template-tag",310841038);
Tha=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);NO=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.OO=new $CLJS.N(null,"context","context",-830191113);PO=new $CLJS.w(null,"get-year","get-year",704520253,null);Uha=new $CLJS.N(null,"format-rows?","format-rows?",992129486);$CLJS.QO=new $CLJS.N(null,"parameters","parameters",-1229919748);Vha=new $CLJS.N(null,"json-download","json-download",-971130133);
RO=new $CLJS.N(null,"string-or-field","string-or-field",-1970678542);SO=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);Wha=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Xha=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);$CLJS.TO=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.UO=new $CLJS.N("date","month-year","date/month-year",1948031290);
VO=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);Yha=new $CLJS.w("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-469303836,null);WO=new $CLJS.w("s","recursive","s/recursive",-1935549792,null);$CLJS.XO=new $CLJS.N(null,"joins","joins",1033962699);Zha=new $CLJS.N(null,"slug","slug",2029314850);$CLJS.YO=new $CLJS.N(null,"source-field","source-field",933829534);ZO=new $CLJS.w(null,"Field","Field",430385967,null);
$O=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);aP=new $CLJS.w("helpers","IntGreaterThanZero","helpers/IntGreaterThanZero",1743901629,null);$ha=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);aia=new $CLJS.N(null,"disable-max-results?","disable-max-results?",857693204);bia=new $CLJS.N(null,"items","items",1031954938);bP=new $CLJS.N(null,"more","more",-2058821800);
cP=new $CLJS.N(null,"first-clause","first-clause",-20953491);dP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);cia=new $CLJS.w("metabase.mbql.schema","NumericExpression*","metabase.mbql.schema/NumericExpression*",-254856038,null);dia=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);eP=new $CLJS.w(null,"contains","contains",-1977535957,null);eia=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);
fia=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);gia=new $CLJS.w(null,"BooleanExpression","BooleanExpression",1027887421,null);fP=new $CLJS.N("number","\x3d","number/\x3d",-2094581309);$CLJS.gP=new $CLJS.N(null,"fingerprint","fingerprint",598613022);hP=new $CLJS.w(null,"is-null","is-null",-356519403,null);iP=new $CLJS.N(null,"other-clauses","other-clauses",1570511021);hia=new $CLJS.N(null,"map-tiles","map-tiles",1961865797);
iia=new $CLJS.N(null,"required","required",1807647006);jia=new $CLJS.w(null,"Filter*","Filter*",-1193205185,null);kia=new $CLJS.N(null,"datetime-x","datetime-x",1519265947);lia=new $CLJS.N(null,"datetime-y","datetime-y",-1666955771);jP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);kP=new $CLJS.N(null,"date-arithmetics","date-arithmetics",-1832808309);lP=new $CLJS.N("string","contains","string/contains",1602423827);
mia=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);mP=new $CLJS.N("string","!\x3d","string/!\x3d",-1083772573);nia=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);oia=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);pia=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);
nP=new $CLJS.w(null,"share","share",1051097594,null);qia=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);ria=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);sia=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);tia=new $CLJS.N(null,"xlsx-download","xlsx-download",-1622892009);
oP=new $CLJS.w(null,"FieldOrAggregationReference","FieldOrAggregationReference",85477856,null);uia=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);pP=new $CLJS.N(null,"collection","collection",-683361892);$CLJS.qP=new $CLJS.N("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);via=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);rP=new $CLJS.N("number","\x3c\x3d","number/\x3c\x3d",-1499316353);
sP=new $CLJS.w(null,"Aggregation","Aggregation",-955933538,null);tP=new $CLJS.w(null,"metric","metric",2049329604,null);uP=new $CLJS.w(null,"concat","concat",-467652465,null);vP=new $CLJS.N(null,"variable","variable",-281346492);$CLJS.wP=new $CLJS.N("date","quarter-year","date/quarter-year",-1453950150);wia=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.xP=new $CLJS.N("date","relative","date/relative",25987732);$CLJS.yP=new $CLJS.N(null,"alias","alias",-2039751630);
zP=new $CLJS.N("location","city","location/city",-1746973325);xia=new $CLJS.N(null,"public-dashboard","public-dashboard",645968405);yia=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);AP=new $CLJS.N("number","\x3e\x3d","number/\x3e\x3d",-1670691032);BP=new $CLJS.N("number","between","number/between",97700581);zia=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);CP=new $CLJS.w(null,"sqrt","sqrt",370479598,null);
DP=new $CLJS.w("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",-1440012226,null);$CLJS.EP=new $CLJS.N(null,"semantic_type","semantic_type",272485089);Aia=new $CLJS.N(null,"metric-id","metric-id",-686486942);FP=new $CLJS.w(null,"*","*",345799209,null);GP=new $CLJS.w(null,"+","+",-740910886,null);HP=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.IP=new $CLJS.N(null,"breakout","breakout",-732419050);JP=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);
KP=new $CLJS.N(null,"allowed-for","allowed-for",122724334);Bia=new $CLJS.N(null,"question","question",-1411720117);LP=new $CLJS.w(null,"asc","asc",1997386096,null);Cia=new $CLJS.w("metabase.mbql.schema","DatetimeExpression*","metabase.mbql.schema/DatetimeExpression*",-1766428848,null);Dia=new $CLJS.N(null,"pulse-id","pulse-id",1331432237);Eia=new $CLJS.w("metabase.mbql.schema","BooleanExpression*","metabase.mbql.schema/BooleanExpression*",-630544184,null);
MP=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);NP=new $CLJS.w(null,"\x3c","\x3c",993667236,null);OP=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);$CLJS.PP=new $CLJS.N(null,"aggregation","aggregation",1597476696);$CLJS.QP=new $CLJS.N(null,"source-metadata","source-metadata",-477816085);RP=new $CLJS.N("string","ends-with","string/ends-with",302681156);Fia=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);
$CLJS.SP=new $CLJS.w("s","Str","s/Str",907974338,null);TP=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);UP=new $CLJS.w(null,"and","and",668631710,null);VP=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);WP=new $CLJS.w(null,"round","round",-645002441,null);Gia=new $CLJS.N(null,"to","to",192099007);$CLJS.XP=new $CLJS.N("date","single","date/single",1554682003);Hia=new $CLJS.w(null,"DatetimeExpression*","DatetimeExpression*",1609939947,null);
Iia=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);YP=new $CLJS.w(null,"exp","exp",1378825265,null);ZP=new $CLJS.w(null,"Filter","Filter",-424893332,null);Jia=new $CLJS.w("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",1964355302,null);$P=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Kia=new $CLJS.N(null,"dashboard-id","dashboard-id",1965414288);
Lia=new $CLJS.w(null,"BooleanExpression*","BooleanExpression*",623370113,null);$CLJS.aQ=new $CLJS.N(null,"source-table","source-table",-225307692);Mia=new $CLJS.N(null,"embedded-dashboard","embedded-dashboard",-485078014);$CLJS.bQ=new $CLJS.N("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);Nia=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);$CLJS.cQ=new $CLJS.N(null,"metric","metric",408798077);
dQ=new $CLJS.w(null,"floor","floor",-772394748,null);Oia=new $CLJS.N(null,"middleware","middleware",1462115504);Pia=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);eQ=new $CLJS.N(null,"requires-features","requires-features",-101116256);fQ=new $CLJS.N(null,"clause-name","clause-name",-996419059);gQ=new $CLJS.w(null,"now","now",-9994004,null);Qia=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);
hQ=new $CLJS.w(null,"avg","avg",1837937727,null);iQ=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);jQ=new $CLJS.N(null,"max-results","max-results",-32858165);Ria=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);kQ=new $CLJS.w(null,"case","case",-1510733573,null);Sia=new $CLJS.w("js","Date","js/Date",946858373,null);Tia=new $CLJS.w(null,"StringExpression*","StringExpression*",1110382599,null);
lQ=new $CLJS.w(null,"distinct","distinct",-148347594,null);mQ=new $CLJS.w(null,"get-second","get-second",-425414791,null);nQ=new $CLJS.w(null,"DatetimeExpression","DatetimeExpression",1944354145,null);$CLJS.oQ=new $CLJS.N(null,"join-alias","join-alias",1454206794);pQ=new $CLJS.w(null,"is-empty","is-empty",600228619,null);qQ=new $CLJS.w(null,"StringExpression","StringExpression",-233088561,null);Uia=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
$CLJS.rQ=new $CLJS.w("s","pred","s/pred",-727014287,null);sQ=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);tQ=new $CLJS.N("number","!\x3d","number/!\x3d",-673025509);uQ=new $CLJS.w(null,"abs","abs",1394505050,null);vQ=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);Via=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);$CLJS.wQ=new $CLJS.w("s","cond-pre","s/cond-pre",-923707600,null);
xQ=new $CLJS.N(null,"date","date",-1463434462);yQ=new $CLJS.N(null,"second-clause","second-clause",-461435645);$CLJS.zQ=new $CLJS.N(null,"left-join","left-join",-672831855);Wia=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);AQ=new $CLJS.w(null,"rtrim","rtrim",979195078,null);BQ=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);CQ=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);
DQ=new $CLJS.w("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",-1444327113,null);EQ=new $CLJS.w(null,"or","or",1876275696,null);FQ=new $CLJS.w("s","Int","s/Int",-2116888657,null);$CLJS.GQ=new $CLJS.N(null,"field","field",-1302436500);Xia=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Yia=new $CLJS.w(null,"TemporalExtractUnits","TemporalExtractUnits",-1308527018,null);Zia=new $CLJS.N(null,"constraints","constraints",422775616);
$ia=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);aja=new $CLJS.N(null,"csv-download","csv-download",2141432084);HQ=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);bja=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);cja=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);
IQ=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);JQ=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);KQ=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);dja=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);LQ=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);
eja=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);MQ=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);fja=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);gja=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);NQ=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);
hja=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);ija=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);jja=new $CLJS.N(null,"display_name","display_name",-1494335013);kja=new $CLJS.N(null,"snippet-id","snippet-id",1987785841);$CLJS.OQ=new $CLJS.N(null,"database","database",1849087575);PQ=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);
QQ=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);lja=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);$CLJS.RQ=new $CLJS.N(null,"expressions","expressions",255689909);mja=new $CLJS.N(null,"ad-hoc","ad-hoc",-2033634036);nja=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);$CLJS.SQ=new $CLJS.N(null,"temporal-unit","temporal-unit",-1892306358);
TQ=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);oja=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);UQ=new $CLJS.w(null,"get-day","get-day",1768100384,null);pja=new $CLJS.w(null,"Fields","Fields",430617156,null);VQ=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.WQ=new $CLJS.N(null,"native","native",-613060878);qja=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);
XQ=new $CLJS.N(null,"page","page",849072397);YQ=new $CLJS.w(null,"length","length",-2065447907,null);rja=new $CLJS.N(null,"dashboard","dashboard",-631747508);ZQ=new $CLJS.w(null,"get-week","get-week",752472178,null);$Q=new $CLJS.w(null,"get-month","get-month",1271156796,null);aR=new $CLJS.w(null,"dimension","dimension",-2111181571,null);bR=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);
cR=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);sja=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);dR=new $CLJS.w(null,"substring","substring",-1513569493,null);tja=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);eR=new $CLJS.N(null,"internal","internal",-854870097);uja=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);
vja=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);fR=new $CLJS.N(null,"declared","declared",92336021);gR=new $CLJS.N(null,"more-values-or-fields","more-values-or-fields",-886177554);hR=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);wja=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);xja=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);
iR=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);yja=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);zja=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);jR=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.kR=new $CLJS.N(null,"template-tags","template-tags",1853115685);Aja=new $CLJS.N(null,"public-question","public-question",629369976);
Bja=new $CLJS.w("metabase.mbql.schema","Field*","metabase.mbql.schema/Field*",-1168278623,null);$CLJS.lR=new $CLJS.N(null,"fk-field-id","fk-field-id",1124127357);Cja=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Dja=new $CLJS.N(null,"binary","binary",-1802232288);mR=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Eja=new $CLJS.N(null,"userland-query?","userland-query?",-123699383);
$CLJS.nR=new $CLJS.N(null,"source-query","source-query",198004422);$CLJS.oR=new $CLJS.N(null,"settings","settings",1556144875);Fja=new $CLJS.w("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",-781973827,null);$CLJS.pR=new $CLJS.N(null,"inner-join","inner-join",659431740);Gja=new $CLJS.w(null,"Field*","Field*",-2104480026,null);Hja=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);Ija=new $CLJS.w("s","eq","s/eq",1021992833,null);
Jja=new $CLJS.N(null,"executed-by","executed-by",-739811161);Kja=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);qR=new $CLJS.N(null,"amount","amount",364489504);rR=new $CLJS.w(null,"percentile","percentile",1039342775,null);sR=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);tR=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);uR=new $CLJS.w(null,"trim","trim",-1880116002,null);
Lja=new $CLJS.w(null,"MBQLQuery","MBQLQuery",-1395590243,null);vR=new $CLJS.w(null,"NumericExpression","NumericExpression",-2082340941,null);wR=new $CLJS.w("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",-859895030,null);xR=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);yR=new $CLJS.N("string","\x3d","string/\x3d",983744235);Mja=new $CLJS.N(null,"skip-results-metadata?","skip-results-metadata?",251010463);
Nja=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Oja=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);Pja=new $CLJS.w(null,"DatetimeDiffUnits","DatetimeDiffUnits",1899101514,null);zR=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);AR=new $CLJS.N(null,"value-or-field","value-or-field",-1387286309);BR=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);
Qja=new $CLJS.N(null,"lat-min","lat-min",1630784161);CR=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);DR=new $CLJS.w(null,"inside","inside",-681932758,null);Rja=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);Sja=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);ER=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
Tja=new $CLJS.N(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);FR=new $CLJS.N(null,"variadic","variadic",882626057);$CLJS.GR=new $CLJS.N(null,"category","category",-593092832);Vja=new $CLJS.w(null,"NumericExpression*","NumericExpression*",-1459070895,null);HR=new $CLJS.w(null,"upper","upper",1886775433,null);IR=new $CLJS.N(null,"field-or-expression","field-or-expression",-1409494368);
Wja=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);JR=new $CLJS.N(null,"sugar","sugar",-73788488);Xja=new $CLJS.N(null,"lat-max","lat-max",841568226);$CLJS.KR=new $CLJS.N(null,"full-join","full-join",1305476385);LR=new $CLJS.w(null,"power","power",702679448,null);MR=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);Yja=new $CLJS.w("metabase.mbql.schema","StringExpression*","metabase.mbql.schema/StringExpression*",224546636,null);
NR=new $CLJS.w(null,"median","median",-2084869638,null);OR=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);Zja=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);PR=new $CLJS.N(null,"y","y",-1757859776);$ja=new $CLJS.w("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",-823028985,null);QR=new $CLJS.w("helpers","NonBlankString","helpers/NonBlankString",-2060904596,null);
aka=new $CLJS.w("metabase.mbql.schema","UnnamedAggregation*","metabase.mbql.schema/UnnamedAggregation*",652628682,null);$CLJS.RR=new $CLJS.N(null,"binning","binning",1709835866);SR=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);TR=new $CLJS.N(null,"b","b",1482224470);UR=new $CLJS.N(null,"a","a",-2123407586);VR=new $CLJS.w(null,"replace","replace",853943757,null);bka=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);
WR=new $CLJS.w(null,"segment","segment",675610331,null);cka=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);YR=new $CLJS.w(null,"ExtractWeekModes","ExtractWeekModes",687144014,null);dka=new $CLJS.w(null,"UnnamedAggregation*","UnnamedAggregation*",1873018243,null);ZR=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);$R=new $CLJS.N("string","starts-with","string/starts-with",1266861170);
$CLJS.aS=new $CLJS.N(null,"order-by","order-by",1527318070);bS=new $CLJS.N(null,"advanced-math-expressions","advanced-math-expressions",1076581757);eka=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);fka=new $CLJS.N("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);gka=new $CLJS.w("metabase.mbql.schema","Filter*","metabase.mbql.schema/Filter*",-140275836,null);$CLJS.cS=new $CLJS.N(null,"condition","condition",1668437652);
dS=new $CLJS.N("string","does-not-contain","string/does-not-contain",-1536178964);eS=new $CLJS.w("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",970196104,null);hka=new $CLJS.N(null,"report-timezone","report-timezone",-2053796389);fS=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);ika=new $CLJS.N(null,"card-name","card-name",-2035606807);
jka=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);kka=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);gS=new $CLJS.w(null,"log","log",45015523,null);lka=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);hS=new $CLJS.w(null,"time","time",-1268547887,null);
mka=new $CLJS.w("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-475072702,null);$CLJS.iS=new $CLJS.N(null,"database_type","database_type",-54700895);jS=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);kS=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.lS=new $CLJS.N(null,"clause","clause",1479668060);$CLJS.mS=new $CLJS.N(null,"expression-name","expression-name",-1798450709);
$CLJS.nS=new $CLJS.N("date","all-options","date/all-options",549325958);nka=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);oka=new $CLJS.w(null,"WidgetType","WidgetType",-1985035186,null);oS=new $CLJS.N("location","state","location/state",-114378652);pS=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);pka=new $CLJS.N(null,"lon-field","lon-field",517872067);
qS=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);rS=new $CLJS.N(null,"numeric","numeric",-1495594714);sS=new $CLJS.w(null,"variable","variable",1359185035,null);tS=new $CLJS.w(null,"lower","lower",-1534114948,null);$CLJS.uS=new $CLJS.N(null,"limit","limit",-1355822363);qka=new $CLJS.w("s","enum","s/enum",-975416934,null);rka=new $CLJS.N(null,"pulse","pulse",-244494476);var vS,wS,xS,ska,tka,uka,vka,AS,BS,wka,xka,yka,CS,zka,DS,ES,FS,Aka,GS,Bka,HS,IS,Cka,Dka,MS,Eka,PS,RS,SS,TS,US,VS,YS,$S,bT,cT,Fka,dT,eT,fT,gT,hT,iT,jT,kT,lT,mT,nT,oT,pT,qT,rT,sT,tT,uT,vT,wT,xT,yT,zT,WS,BT,CT,DT,ET,FT,GT,HT,IT,JT,KT,LT,MT,NT,OT,PT,QT,RT,QS,UT,VT,WT,XT,YT,ZT,$T,aU,bU,cU,dU,eU,fU,gU,hU,iU,jU,kU,lU,mU,nU,oU,pU,Gka,qU,rU,aT,sU,tU,Hka,Ika,uU,AT,ST,wU,xU,yU,zU,AU,BU,CU,DU,EU,FU,GU,HU,IU,JU,KU,LU,MU,NU,Jka,PU,QU,SU,Kka,Lka,TU,Mka,Nka,Oka,Pka,Qka,VU,XU,YU,$U,Rka,Ska,Tka,aV,bV,Uka,UU,cV,dV,
eV,Vka,Wka,Xka,Yka,Zka,$ka,ala,bla,cla;vS=new $CLJS.ni(null,new $CLJS.k(null,12,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.Yk,null,$CLJS.Pj,null,$CLJS.mk,null,$CLJS.jl,null,$CLJS.$l,null,$CLJS.tl,null,$CLJS.Jj,null,$CLJS.sl,null,$CLJS.Uj,null,$CLJS.gk,null],null),null);wS=new $CLJS.ni(null,new $CLJS.k(null,7,[$CLJS.wz,null,$CLJS.sz,null,$CLJS.Pj,null,$CLJS.bk,null,$CLJS.tz,null,$CLJS.Rk,null,$CLJS.uz,null],null),null);xS=$CLJS.yz.h(vS,wS);ska=$CLJS.XM($CLJS.R.h(WN,vS),"date-bucketing-unit");
tka=$CLJS.XM($CLJS.R.h(WN,wS),"time-bucketing-unit");$CLJS.yS=$CLJS.XM($CLJS.R.h(WN,xS),"datetime-bucketing-unit");$CLJS.zS=$CLJS.XM($CLJS.R.h(WN,$CLJS.WL.tz.names()),"timezone-id");uka=$CLJS.XM($CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.$K,null,$CLJS.mk,null,$CLJS.bk,null,$CLJS.$l,null,$CLJS.tl,null,$CLJS.gJ,null,$CLJS.JI,null,$CLJS.VK,null,$CLJS.YI,null,$CLJS.gk,null,$CLJS.Rk,null],null),null)),"temporal-extract-units");
vka=$CLJS.XM($CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Yk,null,$CLJS.sz,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"datetime-diff-units");AS=$CLJS.XM($CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.JJ,null,$CLJS.wI,null,$CLJS.rJ,null],null),null)),"extract-week-modes");
BS=$CLJS.XM($CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Yk,null,$CLJS.Pj,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.uz,null],null),null)),"relative-datetime-unit");wka=$CLJS.ZM($CLJS.MN,kO,"valid ISO-8601 datetime string literal");xka=$CLJS.ZM($CLJS.MN,kO,"valid ISO-8601 datetime string literal");yka=$CLJS.ZM($CLJS.MN,kO,"valid ISO-8601 time string literal");
CS=fO($CLJS.sJ,$CLJS.G(["n",$CLJS.LN($CLJS.G([$CLJS.TM($CLJS.nB),$CLJS.HN])),"unit",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,BS],null)]));zka=fO($CLJS.jJ,$CLJS.G(["n",$CLJS.HN,"unit",BS]));DS=YN.l($CLJS.G([function(a){return $CLJS.Gb(gO($CLJS.GI,a))},fO($CLJS.GI,$CLJS.G(["t",Date,"unit",$CLJS.yS])),function(a){return $CLJS.Zd(a)instanceof Date},fO($CLJS.GI,$CLJS.G(["date",Date,"unit",ska])),$CLJS.Tu,fO($CLJS.GI,$CLJS.G(["datetime",Date,"unit",$CLJS.yS]))]));
ES=fO($CLJS.ZD,$CLJS.G(["time",Date,"unit",tka]));FS=YN.l($CLJS.G([$CLJS.bg(gO,$CLJS.GI),DS,kO,xka,kO,wka,$CLJS.Tu,$CLJS.LN($CLJS.G([Date]))]));Aka=YN.l($CLJS.G([$CLJS.bg(gO,$CLJS.ZD),ES,kO,yka,$CLJS.Tu,$CLJS.LN($CLJS.G([Date]))]));GS=$CLJS.LN($CLJS.G([Aka,FS]));
hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof DS?new $CLJS.ud(function(){return DS},$CLJS.K($ha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,eR,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TQ,"metabase/mbql/schema.cljc",69,$CLJS.GI,1,!0,192,192,$CLJS.yd,"Schema for an `:absolute-datetime` clause.",$CLJS.m(DS)?DS.J:null])):null));return $CLJS.m(a)?a:TQ}(),DS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof CS?new $CLJS.ud(function(){return CS},$CLJS.K(nka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,
$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sQ,"metabase/mbql/schema.cljc",29,$CLJS.sJ,1,174,174,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sJ,$CLJS.uC,$CLJS.X($CLJS.El,$CLJS.X($CLJS.wQ,$CLJS.X(Ija,$CLJS.nB),FQ)),$CLJS.Wj,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,TP))],null),$CLJS.yd,"Schema for a valid relative-datetime clause.",$CLJS.m(CS)?CS.J:null])):null));return $CLJS.m(a)?
a:sQ}(),CS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof ES?new $CLJS.ud(function(){return ES},$CLJS.K(gja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,eR,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hS,"metabase/mbql/schema.cljc",27,$CLJS.ZD,1,!0,225,225,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.ZD,$CLJS.ZD,$CLJS.X($CLJS.El,Sia),$CLJS.Wj,$CLJS.X($CLJS.El,wia)],null),$CLJS.yd,"Schema for a valid time clause.",$CLJS.m(ES)?ES.J:null])):null));return $CLJS.m(a)?a:hS}(),ES],null)]));Bka=$CLJS.Pf([UN($CLJS.iS),$CLJS.cO($CLJS.MN),UN($CLJS.uJ),$CLJS.cO($CLJS.NN),UN($CLJS.EP),$CLJS.cO($CLJS.ON),UN($CLJS.Wj),$CLJS.cO($CLJS.yS),UN($CLJS.V),$CLJS.cO($CLJS.MN),$CLJS.IN,$CLJS.DN]);
HS=fO($CLJS.ql,$CLJS.G(["value",$CLJS.DN,"type-info",$CLJS.cO(Bka)]));IS=fO($CLJS.AG,$CLJS.G(["expression-name",$CLJS.MN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.VM($CLJS.oe,"map")],null)]));Cka=WN.l($CLJS.G([$CLJS.DI,$CLJS.UK,$CLJS.Pj]));
Dka=function(a){return $CLJS.ZM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.XK);c=$CLJS.M.h(c,$CLJS.DI);return $CLJS.F.h(b,$CLJS.DI)?c:!0},"You must specify :num-bins when using the :num-bins strategy.")}(function(a){return $CLJS.ZM(a,function(b){var c=$CLJS.Qf(b);b=$CLJS.M.h(c,$CLJS.XK);c=$CLJS.M.h(c,$CLJS.UK);return $CLJS.F.h(b,$CLJS.UK)?c:!0},"You must specify :bin-width when using the :bin-width strategy.")}($CLJS.Pf([$CLJS.XK,Cka,UN($CLJS.DI),$CLJS.PN,UN($CLJS.UK),$CLJS.ZM($CLJS.GN,$CLJS.$f($CLJS.ef),
"bin width must be \x3e\x3d 0."),$CLJS.IN,$CLJS.DN])));$CLJS.JS=function JS(a){switch(arguments.length){case 1:return JS.g(arguments[0]);case 2:return JS.h(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.g(arguments.length)].join(""));}};$CLJS.JS.g=function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.DG);b=$CLJS.M.h(b,$CLJS.SQ);return $CLJS.JS.h(a,b)};
$CLJS.JS.h=function(a,b){a=$CLJS.m($CLJS.m(b)?a:b)?$CLJS.PH(a,$CLJS.PI)?vS:$CLJS.PH(a,$CLJS.YK)?wS:$CLJS.PH(a,$CLJS.TJ)?xS:null:null;return $CLJS.m(a)?$CLJS.Ie(a,b):!0};$CLJS.JS.v=2;
var KS=$CLJS.ZM($CLJS.ZM($CLJS.Pf([UN($CLJS.DG),$CLJS.cO($CLJS.NN),UN($CLJS.YO),$CLJS.cO($CLJS.LN($CLJS.G([$CLJS.PN,$CLJS.MN]))),UN($CLJS.SQ),$CLJS.cO($CLJS.yS),UN($CLJS.oQ),$CLJS.cO($CLJS.MN),UN($CLJS.RR),$CLJS.cO(Dka),$CLJS.IN,$CLJS.DN]),$CLJS.JS,"Invalid :temporal-unit for the specified :base-type."),$CLJS.$f($CLJS.XK),"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."),LS=function(a){return $CLJS.ZM(a,function(b){$CLJS.J(b,0,null);
var c=$CLJS.J(b,1,null);b=$CLJS.J(b,2,null);b=$CLJS.Qf(b);b=$CLJS.M.h(b,$CLJS.DG);return"string"===typeof c?b:!0},":field clauses using a string field name must specify :base-type.")}(fO($CLJS.GQ,$CLJS.G(["id-or-name",$CLJS.LN($CLJS.G([$CLJS.PN,$CLJS.MN])),"options",$CLJS.cO(aO(new $CLJS.ud(function(){return KS},mka,$CLJS.Sh([$CLJS.nm,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[!0,$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),Lha,"metabase/mbql/schema.cljc",28,1,383,383,$CLJS.yd,null,$CLJS.m(KS)?KS.J:null]))))])));$CLJS.ZM(LS,function(a){$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return $CLJS.Ae(a)},"Must be a :field with an integer Field ID.");
MS=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof IS?new $CLJS.ud(function(){return IS},$CLJS.K(fia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.TO,"metabase/mbql/schema.cljc",60,$CLJS.AG,1,318,318,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.AG,$CLJS.mS,$CLJS.X($CLJS.El,QR),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,$CLJS.X($CLJS.rQ,$CLJS.ek,"map")))],null),$CLJS.yd,"Schema for a valid expression clause.",$CLJS.m(IS)?IS.J:null])):null));return $CLJS.m(a)?a:$CLJS.TO}(),IS],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof LS?
new $CLJS.ud(function(){return LS},$CLJS.K(pia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.al,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],["0.39.0",$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KO,"metabase/mbql/schema.cljc",51,$CLJS.GQ,1,429,429,$CLJS.yd,"Schema for a `:field` clause.",$CLJS.m(LS)?LS.J:null])):null));return $CLJS.m(a)?
a:KO}(),LS],null)]));$CLJS.NS=aO(new $CLJS.ud(function(){return MS},Bja,$CLJS.Sh([$CLJS.nm,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[!0,$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Gja,"metabase/mbql/schema.cljc",22,1,445,445,$CLJS.yd,null,$CLJS.m(MS)?MS.J:null])));
Eka=fO($CLJS.PP,$CLJS.G(["aggregation-clause-index",$CLJS.HN,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.VM($CLJS.oe,"map")],null)]));$CLJS.OS=ZN($CLJS.bg(gO,$CLJS.PP),Eka,$CLJS.NS);PS=new $CLJS.ni(null,new $CLJS.k(null,11,[$CLJS.Ru,null,$CLJS.HK,null,$CLJS.bI,null,$CLJS.WK,null,$CLJS.pI,null,$CLJS.RJ,null,$CLJS.$I,null,$CLJS.$u,null,$CLJS.CK,null,$CLJS.PJ,null,$CLJS.ZK,null],null),null);
RS=YN.l($CLJS.G([$CLJS.Jb,$CLJS.EN,$CLJS.bg(gO,PS),aO(new $CLJS.ud(function(){return QS},eS,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(QS)?QS.J:null]))),$CLJS.bg(gO,$CLJS.ql),HS,$CLJS.Tu,$CLJS.NS]));
SS=new $CLJS.ni(null,new $CLJS.k(null,26,[$CLJS.kI,null,$CLJS.TK,null,$CLJS.lm,null,$CLJS.pK,null,$CLJS.hK,null,$CLJS.pJ,null,$CLJS.xu,null,$CLJS.$H,null,$CLJS.xK,null,$CLJS.dK,null,$CLJS.RJ,null,$CLJS.QK,null,$CLJS.kK,null,$CLJS.WJ,null,$CLJS.nI,null,$CLJS.EI,null,$CLJS.km,null,$CLJS.uI,null,$CLJS.$I,null,$CLJS.ZH,null,$CLJS.jD,null,$CLJS.FI,null,$CLJS.bK,null,$CLJS.iJ,null,$CLJS.SK,null,$CLJS.HJ,null],null),null);
TS=new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.sm,null,$CLJS.im,null,$CLJS.Bk,null,$CLJS.Tk,null,$CLJS.rm,null,$CLJS.DK,null,$CLJS.Lj,null,$CLJS.fm,null,$CLJS.gm,null],null),null);US=new $CLJS.ni(null,new $CLJS.k(null,17,[$CLJS.qk,null,$CLJS.AK,null,$CLJS.fK,null,$CLJS.NJ,null,$CLJS.OK,null,$CLJS.RK,null,$CLJS.NI,null,$CLJS.nJ,null,$CLJS.qO,null,$CLJS.RI,null,$CLJS.vK,null,$CLJS.ZJ,null,$CLJS.Xl,null,$CLJS.lD,null,$CLJS.DJ,null,$CLJS.cQ,null,$CLJS.tK,null],null),null);
VS=new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.EJ,null,$CLJS.vI,null,$CLJS.MK,null,$CLJS.km,null,$CLJS.eK,null],null),null);
YS=YN.l($CLJS.G([$CLJS.Eb,$CLJS.GN,$CLJS.bg(gO,SS),aO(new $CLJS.ud(function(){return WS},wR,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(WS)?WS.J:null]))),$CLJS.bg(gO,US),aO(new $CLJS.ud(function(){return $CLJS.XS},AO,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.XS)?$CLJS.XS.J:null]))),$CLJS.bg(gO,$CLJS.ql),HS,$CLJS.Tu,$CLJS.NS]));
$S=YN.l($CLJS.G([$CLJS.bg(gO,US),aO(new $CLJS.ud(function(){return $CLJS.XS},AO,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",21,1,525,!0,525,$CLJS.yd,null,$CLJS.m($CLJS.XS)?$CLJS.XS.J:null]))),$CLJS.bg(gO,$CLJS.ql),HS,$CLJS.bg(gO,VS),aO(new $CLJS.ud(function(){return $CLJS.ZS},
DP,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.ZS)?$CLJS.ZS.J:null]))),$CLJS.Tu,$CLJS.LN($CLJS.G([FS,$CLJS.NS]))]));
bT=YN.l($CLJS.G([$CLJS.Eb,$CLJS.GN,$CLJS.ve,$CLJS.FN,$CLJS.bg(gO,TS),aO(new $CLJS.ud(function(){return aT},Yha,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gia,"metabase/mbql/schema.cljc",27,1,523,!0,523,$CLJS.yd,null,$CLJS.m(aT)?aT.J:null]))),$CLJS.bg(gO,SS),aO(new $CLJS.ud(function(){return WS},
wR,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",27,1,522,!0,522,$CLJS.yd,null,$CLJS.m(WS)?WS.J:null]))),$CLJS.bg(gO,VS),aO(new $CLJS.ud(function(){return $CLJS.ZS},DP,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],
[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",28,1,524,!0,524,$CLJS.yd,null,$CLJS.m($CLJS.ZS)?$CLJS.ZS.J:null]))),$CLJS.Jb,$CLJS.EN,$CLJS.bg(gO,PS),aO(new $CLJS.ud(function(){return QS},eS,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qQ,"metabase/mbql/schema.cljc",26,1,488,!0,488,$CLJS.yd,null,$CLJS.m(QS)?QS.J:null]))),$CLJS.bg(gO,$CLJS.ql),HS,$CLJS.Tu,$CLJS.NS]));cT=ZN($CLJS.bg(gO,$CLJS.jJ),zka,YS);Fka=ZN($CLJS.Eb,$CLJS.PN,YS);dT=fO($CLJS.RJ,$CLJS.G(["a",bT,"b",bT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,bT],null)]));eT=fO($CLJS.bI,$CLJS.G(["s",RS,"start",Fka,"length",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,YS],null)]));fT=fO($CLJS.jD,$CLJS.G(["s",RS]));gT=fO($CLJS.CK,$CLJS.G(["s",RS]));
hT=fO($CLJS.ZK,$CLJS.G(["s",RS]));iT=fO($CLJS.HK,$CLJS.G(["s",RS]));jT=fO($CLJS.Ru,$CLJS.G(["s",RS]));kT=fO($CLJS.$u,$CLJS.G(["s",RS]));lT=fO($CLJS.WK,$CLJS.G(["s",RS,"match",$CLJS.EN,"replacement",$CLJS.EN]));mT=fO($CLJS.pI,$CLJS.G(["a",RS,"b",RS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,RS],null)]));nT=fO($CLJS.PJ,$CLJS.G(["s",RS,"pattern",$CLJS.EN]));oT=fO($CLJS.km,$CLJS.G(["x",cT,"y",cT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,cT],null)]));
pT=fO($CLJS.xu,$CLJS.G(["x",YS,"y",cT,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,cT],null)]));qT=fO($CLJS.QK,$CLJS.G(["x",YS,"y",YS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YS],null)]));rT=fO($CLJS.lm,$CLJS.G(["x",YS,"y",YS,"more",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YS],null)]));sT=fO($CLJS.HJ,$CLJS.G(["x",YS]));tT=fO($CLJS.kI,$CLJS.G(["x",YS]));uT=fO($CLJS.WJ,$CLJS.G(["x",YS]));vT=fO($CLJS.uI,$CLJS.G(["x",YS]));wT=fO($CLJS.nI,$CLJS.G(["x",YS,"y",YS]));xT=fO($CLJS.pJ,$CLJS.G(["x",YS]));
yT=fO($CLJS.$H,$CLJS.G(["x",YS]));zT=fO($CLJS.SK,$CLJS.G(["x",YS]));WS=aO(new $CLJS.ud(function(){return AT},cia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Vja,"metabase/mbql/schema.cljc",28,1,664,!0,664,$CLJS.yd,null,$CLJS.m(AT)?AT.J:null])));
BT=fO($CLJS.iJ,$CLJS.G(["datetime-x",$S,"datetime-y",$S,"unit",vka]));CT=fO($CLJS.pK,$CLJS.G(["datetime",$S,"unit",uka,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,AS],null)]));DT=fO($CLJS.FI,$CLJS.G(["date",$S]));ET=fO($CLJS.TK,$CLJS.G(["date",$S]));FT=fO($CLJS.ZH,$CLJS.G(["date",$S]));GT=fO($CLJS.dK,$CLJS.G(["date",$S,"mode",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,AS],null)]));HT=fO($CLJS.bK,$CLJS.G(["date",$S]));IT=fO($CLJS.kK,$CLJS.G(["date",$S]));JT=fO($CLJS.xK,$CLJS.G(["datetime",$S]));
KT=fO($CLJS.hK,$CLJS.G(["datetime",$S]));LT=fO($CLJS.EI,$CLJS.G(["datetime",$S]));MT=fO($CLJS.vI,$CLJS.G(["datetime",$S,"to",$CLJS.zS,"from",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.zS],null)]));NT=$CLJS.XM($CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,9,[$CLJS.Tj,null,$CLJS.xz,null,$CLJS.wz,null,$CLJS.Yk,null,$CLJS.sz,null,$CLJS.jl,null,$CLJS.Jj,null,$CLJS.tz,null,$CLJS.uz,null],null),null)),"arithmetic-datetime-unit");OT=fO($CLJS.eK,$CLJS.G(["datetime",$S,"amount",YS,"unit",NT]));
PT=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return fO(arguments[0],1<b.length?new $CLJS.z(b.slice(1),0,null):null)}($CLJS.EJ);QT=fO($CLJS.MK,$CLJS.G(["datetime",$S,"amount",YS,"unit",NT]));
RT=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},$CLJS.K(MQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GP,"metabase/mbql/schema.cljc",51,$CLJS.km,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.km,$CLJS.JG,$CLJS.X($CLJS.El,PQ),PR,$CLJS.X($CLJS.El,PQ),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,PQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:GP}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof OT?new $CLJS.ud(function(){return OT},
$CLJS.K(Qia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[kP,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HQ,"metabase/mbql/schema.cljc",67,$CLJS.eK,1,729,729,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.eK,$CLJS.CJ,$CLJS.X($CLJS.El,qS),qR,$CLJS.X($CLJS.El,
tR),$CLJS.Wj,$CLJS.X($CLJS.El,CR)],null),$CLJS.yd,"Schema for a valid datetime-add clause.",$CLJS.m(OT)?OT.J:null])):null));return $CLJS.m(a)?a:HQ}(),OT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof QT?new $CLJS.ud(function(){return QT},$CLJS.K(yha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,
$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[kP,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pS,"metabase/mbql/schema.cljc",72,$CLJS.MK,1,736,736,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.MK,$CLJS.CJ,$CLJS.X($CLJS.El,qS),qR,$CLJS.X($CLJS.El,tR),$CLJS.Wj,$CLJS.X($CLJS.El,CR)],null),$CLJS.yd,"Schema for a valid datetime-subtract clause.",$CLJS.m(QT)?QT.J:null])):null));return $CLJS.m(a)?
a:pS}(),QT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof MT?new $CLJS.ud(function(){return MT},$CLJS.K(Sja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.vI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",71,$CLJS.vI,1,719,719,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.vI,$CLJS.CJ,$CLJS.X($CLJS.El,qS),Gia,$CLJS.X($CLJS.El,SR),Dha,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,SR))],null),$CLJS.yd,"Schema for a valid convert-timezone clause.",$CLJS.m(MT)?MT.J:null])):null));return $CLJS.m(a)?a:$O}(),MT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof PT?new $CLJS.ud(function(){return PT},
$CLJS.K(sja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.EJ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",45,$CLJS.EJ,1,734,734,new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.EJ],null),$CLJS.yd,"Schema for a valid now clause.",
$CLJS.m(PT)?PT.J:null])):null));return $CLJS.m(a)?a:gQ}(),PT],null)]));$CLJS.ZS=aO(new $CLJS.ud(function(){return RT},Cia,$CLJS.Sh([$CLJS.nm,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[!0,$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Hia,"metabase/mbql/schema.cljc",35,1,741,741,$CLJS.yd,null,$CLJS.m(RT)?RT.J:null])));
QS=aO(new $CLJS.ud(function(){return ST},Yja,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Tia,"metabase/mbql/schema.cljc",27,1,748,!0,748,$CLJS.yd,null,$CLJS.m(ST)?ST.J:null])));
UT=fO($CLJS.Lj,$CLJS.G(["first-clause",aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null]))),"second-clause",aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,
$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null])))],null)]));
VT=fO($CLJS.Bk,$CLJS.G(["first-clause",aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null]))),"second-clause",aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,
$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null]))),"other-clauses",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,
$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null])))],null)]));
WT=fO($CLJS.Tk,$CLJS.G(["clause",aO(new $CLJS.ud(function(){return $CLJS.TT},DQ,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",16,1,756,!0,756,$CLJS.yd,null,$CLJS.m($CLJS.TT)?$CLJS.TT.J:null])))]));XT=ZN($CLJS.bg(gO,$CLJS.sJ),CS,$CLJS.NS);
YT=$CLJS.cO($CLJS.LN($CLJS.G([$CLJS.FN,$CLJS.GN,$CLJS.EN,GS,XT,bT,HS])));ZT=ZN($CLJS.bg(gO,$CLJS.ql),HS,$CLJS.LN($CLJS.G([$CLJS.GN,$CLJS.EN,GS,bT,XT])));$T=fO($CLJS.fm,$CLJS.G(["field",YT,"value-or-field",YT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YT],null)]));aU=fO($CLJS.DK,$CLJS.G(["field",YT,"value-or-field",YT,"more-values-or-fields",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.xk,YT],null)]));bU=fO($CLJS.gm,$CLJS.G(["field",ZT,"value-or-field",ZT]));
cU=fO($CLJS.im,$CLJS.G(["field",ZT,"value-or-field",ZT]));dU=fO($CLJS.sm,$CLJS.G(["field",ZT,"value-or-field",ZT]));eU=fO($CLJS.rm,$CLJS.G(["field",ZT,"value-or-field",ZT]));fU=fO($CLJS.KI,$CLJS.G(["field",ZT,"min",ZT,"max",ZT]));gU=fO($CLJS.VI,$CLJS.G(["lat-field",ZT,"lon-field",ZT,"lat-max",ZT,"lon-min",ZT,"lat-min",ZT,"lon-max",ZT]));hU=fO($CLJS.yI,$CLJS.G(["field",$CLJS.NS]));iU=fO($CLJS.oJ,$CLJS.G(["field",$CLJS.NS]));jU=fO($CLJS.LI,$CLJS.G(["field",$CLJS.NS]));
kU=fO($CLJS.aJ,$CLJS.G(["field",$CLJS.NS]));lU=$CLJS.Pf([UN($CLJS.aK),$CLJS.FN]);mU=fO($CLJS.vJ,$CLJS.G(["field",RS,"string-or-field",RS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,lU],null)]));nU=fO($CLJS.nK,$CLJS.G(["field",RS,"string-or-field",RS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,lU],null)]));oU=fO($CLJS.LJ,$CLJS.G(["field",RS,"string-or-field",RS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,lU],null)]));
pU=fO($CLJS.wJ,$CLJS.G(["field",RS,"string-or-field",RS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,lU],null)]));Gka=$CLJS.Pf([UN($CLJS.yK),$CLJS.FN]);qU=fO($CLJS.rK,$CLJS.G(["field",$CLJS.NS,"n",$CLJS.LN($CLJS.G([$CLJS.HN,WN.l($CLJS.G([$CLJS.nB,$CLJS.pD,$CLJS.SI]))])),"unit",BS,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Gka],null)]));rU=fO($CLJS.oI,$CLJS.G(["segment-id",$CLJS.LN($CLJS.G([$CLJS.PN,$CLJS.MN]))]));
aT=aO(new $CLJS.ud(function(){return sU},Eia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lia,"metabase/mbql/schema.cljc",28,1,883,!0,883,$CLJS.yd,null,$CLJS.m(sU)?sU.J:null])));
sU=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(MR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,cP,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),yQ,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),iP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:UP}(),UT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==
typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(BQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",14,$CLJS.Bk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Bk,cP,$CLJS.X($CLJS.El,
$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),yQ,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),iP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(VT)?VT.J:null])):null));return $CLJS.m(a)?a:EQ}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},$CLJS.K(cR,new $CLJS.k(null,
1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bM,"metabase/mbql/schema.cljc",15,$CLJS.Tk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,$CLJS.lS,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(WT)?WT.J:
null])):null));return $CLJS.m(a)?a:$CLJS.bM}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(KQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NP,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(bU)?bU.J:null])):null));return $CLJS.m(a)?a:NP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(fS,new $CLJS.k(null,1,
[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",14,$CLJS.sm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(dU)?dU.J:null])):
null));return $CLJS.m(a)?a:MP}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OP,"metabase/mbql/schema.cljc",13,$CLJS.im,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.im,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:OP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(ZR,new $CLJS.k(null,1,
[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",14,$CLJS.rm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(eU)?eU.J:null])):
null));return $CLJS.m(a)?a:VP}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(JQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.rN,"metabase/mbql/schema.cljc",13,$CLJS.fm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.fm,$CLJS.GQ,$CLJS.X($CLJS.El,jS),AR,$CLJS.X($CLJS.El,jS),gR,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,jS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:$CLJS.rN}(),$T],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},
$CLJS.K(GO,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",14,$CLJS.DK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DK,$CLJS.GQ,$CLJS.X($CLJS.El,jS),AR,$CLJS.X($CLJS.El,jS),gR,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,jS))],null),
$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:SO}(),aU],null)]));
tU=YN.l($CLJS.G([$CLJS.bg(gO,VS),$CLJS.ZS,$CLJS.bg(gO,SS),WS,$CLJS.bg(gO,PS),QS,$CLJS.bg(gO,TS),aT,$CLJS.Tu,hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof UT?new $CLJS.ud(function(){return UT},$CLJS.K(MR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UP,"metabase/mbql/schema.cljc",15,$CLJS.Lj,1,758,758,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Lj,cP,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),yQ,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),iP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))))],null),$CLJS.yd,"Schema for a valid and clause.",$CLJS.m(UT)?UT.J:null])):null));return $CLJS.m(a)?a:UP}(),UT],
null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof VT?new $CLJS.ud(function(){return VT},$CLJS.K(BQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",14,$CLJS.Bk,1,763,763,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.Bk,cP,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),yQ,$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))),iP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP))))],null),$CLJS.yd,"Schema for a valid or clause.",$CLJS.m(VT)?VT.J:null])):null));return $CLJS.m(a)?a:EQ}(),VT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==
typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof WT?new $CLJS.ud(function(){return WT},$CLJS.K(cR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bM,"metabase/mbql/schema.cljc",15,$CLJS.Tk,1,768,768,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.Tk,$CLJS.lS,
$CLJS.X($CLJS.El,$CLJS.X(WO,$CLJS.X($CLJS.Il,ZP)))],null),$CLJS.yd,"Schema for a valid not clause.",$CLJS.m(WT)?WT.J:null])):null));return $CLJS.m(a)?a:$CLJS.bM}(),WT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof $T?new $CLJS.ud(function(){return $T},$CLJS.K(JQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,
JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.rN,"metabase/mbql/schema.cljc",13,$CLJS.fm,1,811,811,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.fm,$CLJS.GQ,$CLJS.X($CLJS.El,jS),AR,$CLJS.X($CLJS.El,jS),gR,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,jS))],null),$CLJS.yd,"Schema for a valid \x3d clause.",$CLJS.m($T)?$T.J:null])):null));return $CLJS.m(a)?a:$CLJS.rN}(),$T],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof aU?new $CLJS.ud(function(){return aU},$CLJS.K(GO,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",
14,$CLJS.DK,1,812,812,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.DK,$CLJS.GQ,$CLJS.X($CLJS.El,jS),AR,$CLJS.X($CLJS.El,jS),gR,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,jS))],null),$CLJS.yd,"Schema for a valid !\x3d clause.",$CLJS.m(aU)?aU.J:null])):null));return $CLJS.m(a)?a:SO}(),aU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof bU?new $CLJS.ud(function(){return bU},$CLJS.K(KQ,new $CLJS.k(null,
1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",13,$CLJS.gm,1,814,814,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.gm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3c clause.",$CLJS.m(bU)?bU.J:null])):null));
return $CLJS.m(a)?a:NP}(),bU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof cU?new $CLJS.ud(function(){return cU},$CLJS.K(IQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OP,"metabase/mbql/schema.cljc",13,$CLJS.im,1,815,815,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.im,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3e clause.",$CLJS.m(cU)?cU.J:null])):null));return $CLJS.m(a)?a:OP}(),cU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof dU?new $CLJS.ud(function(){return dU},$CLJS.K(fS,new $CLJS.k(null,1,
[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",14,$CLJS.sm,1,816,816,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.sm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3c\x3d clause.",$CLJS.m(dU)?dU.J:null])):
null));return $CLJS.m(a)?a:MP}(),dU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof eU?new $CLJS.ud(function(){return eU},$CLJS.K(ZR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VP,"metabase/mbql/schema.cljc",14,$CLJS.rm,1,817,817,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.rm,$CLJS.GQ,$CLJS.X($CLJS.El,dP),AR,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid \x3e\x3d clause.",$CLJS.m(eU)?eU.J:null])):null));return $CLJS.m(a)?a:VP}(),eU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof fU?new $CLJS.ud(function(){return fU},$CLJS.K(ija,new $CLJS.k(null,
1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IO,"metabase/mbql/schema.cljc",19,$CLJS.KI,1,820,820,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.KI,$CLJS.GQ,$CLJS.X($CLJS.El,dP),$CLJS.qk,$CLJS.X($CLJS.El,dP),$CLJS.Xl,$CLJS.X($CLJS.El,dP)],null),$CLJS.yd,"Schema for a valid between clause.",
$CLJS.m(fU)?fU.J:null])):null));return $CLJS.m(a)?a:IO}(),fU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof mU?new $CLJS.ud(function(){return mU},$CLJS.K(lja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ER,"metabase/mbql/schema.cljc",23,$CLJS.vJ,1,843,843,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.vJ,$CLJS.GQ,$CLJS.X($CLJS.El,zR),RO,$CLJS.X($CLJS.El,zR),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,bR))],null),$CLJS.yd,"Schema for a valid starts-with clause.",$CLJS.m(mU)?mU.J:null])):null));return $CLJS.m(a)?a:ER}(),mU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof nU?new $CLJS.ud(function(){return nU},
$CLJS.K(dia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",21,$CLJS.nK,1,844,844,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.nK,$CLJS.GQ,$CLJS.X($CLJS.El,zR),RO,$CLJS.X($CLJS.El,zR),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,bR))],
null),$CLJS.yd,"Schema for a valid ends-with clause.",$CLJS.m(nU)?nU.J:null])):null));return $CLJS.m(a)?a:VO}(),nU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof oU?new $CLJS.ud(function(){return oU},$CLJS.K(uia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",20,$CLJS.LJ,1,845,845,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.LJ,$CLJS.GQ,$CLJS.X($CLJS.El,zR),RO,$CLJS.X($CLJS.El,zR),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,bR))],null),$CLJS.yd,"Schema for a valid contains clause.",$CLJS.m(oU)?oU.J:null])):null));return $CLJS.m(a)?a:eP}(),oU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==
typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof pU?new $CLJS.ud(function(){return pU},$CLJS.K(kka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",36,$CLJS.wJ,1,848,848,!0,new $CLJS.S(null,
7,5,$CLJS.T,[$CLJS.wJ,$CLJS.GQ,$CLJS.X($CLJS.El,zR),RO,$CLJS.X($CLJS.El,zR),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,bR))],null),$CLJS.yd,"Schema for a valid does-not-contain clause.",$CLJS.m(pU)?pU.J:null])):null));return $CLJS.m(a)?a:pO}(),pU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof gU?new $CLJS.ud(function(){return gU},$CLJS.K(Oja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),
$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",26,$CLJS.VI,1,823,823,!0,new $CLJS.S(null,13,5,$CLJS.T,[$CLJS.VI,Jha,$CLJS.X($CLJS.El,dP),pka,$CLJS.X($CLJS.El,dP),Xja,$CLJS.X($CLJS.El,dP),Nha,$CLJS.X($CLJS.El,dP),Qja,$CLJS.X($CLJS.El,dP),Iha,$CLJS.X($CLJS.El,dP)],
null),$CLJS.yd,"Schema for a valid inside clause.",$CLJS.m(gU)?gU.J:null])):null));return $CLJS.m(a)?a:DR}(),gU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof jU?new $CLJS.ud(function(){return jU},$CLJS.K(via,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",28,$CLJS.LI,1,837,837,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.LI,$CLJS.GQ,$CLJS.X($CLJS.El,ZO)],null),$CLJS.yd,"Schema for a valid is-empty clause.",$CLJS.m(jU)?jU.J:null])):null));return $CLJS.m(a)?a:pQ}(),jU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&
"undefined"!==typeof kU?new $CLJS.ud(function(){return kU},$CLJS.K(yja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",29,$CLJS.aJ,1,838,838,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.aJ,$CLJS.GQ,$CLJS.X($CLJS.El,ZO)],null),
$CLJS.yd,"Schema for a valid not-empty clause.",$CLJS.m(kU)?kU.J:null])):null));return $CLJS.m(a)?a:VQ}(),kU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof hU?new $CLJS.ud(function(){return hU},$CLJS.K(eka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",27,$CLJS.yI,1,832,832,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.yI,$CLJS.GQ,$CLJS.X($CLJS.El,ZO)],null),$CLJS.yd,"Schema for a valid is-null clause.",$CLJS.m(hU)?hU.J:null])):null));return $CLJS.m(a)?a:hP}(),hU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&
"undefined"!==typeof iU?new $CLJS.ud(function(){return iU},$CLJS.K(Iia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",28,$CLJS.oJ,1,833,833,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oJ,$CLJS.GQ,$CLJS.X($CLJS.El,ZO)],null),
$CLJS.yd,"Schema for a valid not-null clause.",$CLJS.m(iU)?iU.J:null])):null));return $CLJS.m(a)?a:MO}(),iU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof qU?new $CLJS.ud(function(){return qU},$CLJS.K(zha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",33,$CLJS.rK,1,868,868,!0,new $CLJS.S(null,9,5,$CLJS.T,[$CLJS.rK,$CLJS.GQ,$CLJS.X($CLJS.El,ZO),$CLJS.uC,$CLJS.X($CLJS.El,$CLJS.X($CLJS.wQ,FQ,$CLJS.X(qka,$CLJS.nB,$CLJS.pD,$CLJS.SI))),$CLJS.Wj,$CLJS.X($CLJS.El,TP),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,oja))],null),$CLJS.yd,"Schema for a valid time-interval clause.",$CLJS.m(qU)?qU.J:null])):null));
return $CLJS.m(a)?a:mR}(),qU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof rU?new $CLJS.ud(function(){return rU},$CLJS.K(Uia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WR,"metabase/mbql/schema.cljc",27,$CLJS.oI,1,881,881,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.oI,Rha,$CLJS.X($CLJS.El,$CLJS.X($CLJS.wQ,aP,QR))],null),$CLJS.yd,"Schema for a valid segment clause.",$CLJS.m(rU)?rU.J:null])):null));return $CLJS.m(a)?a:WR}(),rU],null)]))]));
$CLJS.TT=aO(new $CLJS.ud(function(){return tU},gka,$CLJS.Sh([$CLJS.nm,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[!0,$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jia,"metabase/mbql/schema.cljc",23,1,892,892,$CLJS.yd,null,$CLJS.m(tU)?tU.J:null])));
Hka=new $CLJS.S(null,1,5,$CLJS.T,[new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.TN($CLJS.TT,"pred"),$CLJS.TN(bT,"expr")],null)],null);Ika=$CLJS.Pf([UN($CLJS.Pj),bT]);uU=fO($CLJS.$I,$CLJS.G(["clauses",Hka,"options",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,Ika],null)]));
AT=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof oT?new $CLJS.ud(function(){return oT},$CLJS.K(MQ,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GP,"metabase/mbql/schema.cljc",51,$CLJS.km,1,630,630,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.km,$CLJS.JG,$CLJS.X($CLJS.El,PQ),PR,$CLJS.X($CLJS.El,PQ),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,PQ))],null),$CLJS.yd,"Schema for a valid + clause.",$CLJS.m(oT)?oT.J:null])):null));return $CLJS.m(a)?a:GP}(),oT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof pT?new $CLJS.ud(function(){return pT},
$CLJS.K(eja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HP,"metabase/mbql/schema.cljc",51,$CLJS.xu,1,633,633,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.xu,$CLJS.JG,$CLJS.X($CLJS.El,tR),PR,
$CLJS.X($CLJS.El,PQ),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,PQ))],null),$CLJS.yd,"Schema for a valid - clause.",$CLJS.m(pT)?pT.J:null])):null));return $CLJS.m(a)?a:HP}(),pT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO?new $CLJS.ud(function(){return qT},$CLJS.K(dja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,fQ,$CLJS.Ak,$CLJS.Xk,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Uu,"metabase/mbql/schema.cljc",$CLJS.QK,1,636,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.QK,$CLJS.JG,$CLJS.X($CLJS.El,tR),PR,$CLJS.X($CLJS.El,tR),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,tR))],null),$CLJS.yd,"Schema for a valid / clause.",$CLJS.m(qT)?qT.J:null])):null));return $CLJS.m(a)?a:$CLJS.Uu}(),qT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof rT?new $CLJS.ud(function(){return rT},$CLJS.K(fja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FP,"metabase/mbql/schema.cljc",51,$CLJS.lm,1,638,638,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.lm,$CLJS.JG,$CLJS.X($CLJS.El,tR),PR,$CLJS.X($CLJS.El,tR),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,tR))],null),$CLJS.yd,"Schema for a valid * clause.",$CLJS.m(rT)?rT.J:null])):null));return $CLJS.m(a)?a:FP}(),rT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},
$CLJS.K(iR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",58,$CLJS.RJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.RJ,UR,$CLJS.X($CLJS.El,sO),TR,$CLJS.X($CLJS.El,
sO),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,sO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:xR}(),dT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof fT?new $CLJS.ud(function(){return fT},$CLJS.K(xja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,
$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",56,$CLJS.jD,1,603,603,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.jD,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),$CLJS.yd,"Schema for a valid length clause.",$CLJS.m(fT)?fT.J:null])):null));return $CLJS.m(a)?a:YQ}(),fT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof sT?new $CLJS.ud(function(){return sT},$CLJS.K(eia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dQ,"metabase/mbql/schema.cljc",55,$CLJS.HJ,1,640,640,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HJ,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid floor clause.",$CLJS.m(sT)?sT.J:null])):null));return $CLJS.m(a)?a:dQ}(),sT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof tT?new $CLJS.ud(function(){return tT},$CLJS.K(Xha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),
$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CO,"metabase/mbql/schema.cljc",54,$CLJS.kI,1,643,643,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kI,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid ceil clause.",$CLJS.m(tT)?
tT.J:null])):null));return $CLJS.m(a)?a:CO}(),tT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof uT?new $CLJS.ud(function(){return uT},$CLJS.K(ria,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WP,"metabase/mbql/schema.cljc",55,$CLJS.WJ,1,646,646,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.WJ,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid round clause.",$CLJS.m(uT)?uT.J:null])):null));return $CLJS.m(a)?a:WP}(),uT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&
"undefined"!==typeof vT?new $CLJS.ud(function(){return vT},$CLJS.K(lka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uQ,"metabase/mbql/schema.cljc",53,$CLJS.uI,1,649,649,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.uI,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid abs clause.",$CLJS.m(vT)?vT.J:null])):null));return $CLJS.m(a)?a:uQ}(),vT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof wT?new $CLJS.ud(function(){return wT},$CLJS.K(Uja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,
$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[bS,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LR,"metabase/mbql/schema.cljc",69,$CLJS.nI,1,652,652,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nI,$CLJS.JG,$CLJS.X($CLJS.El,tR),PR,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid power clause.",$CLJS.m(wT)?wT.J:null])):null));return $CLJS.m(a)?a:LR}(),wT],null),
new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof xT?new $CLJS.ud(function(){return xT},$CLJS.K(Nia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[bS,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),CP,"metabase/mbql/schema.cljc",68,$CLJS.pJ,1,655,655,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.pJ,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid sqrt clause.",$CLJS.m(xT)?xT.J:null])):null));return $CLJS.m(a)?a:CP}(),xT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof yT?new $CLJS.ud(function(){return yT},$CLJS.K(Wja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,
$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[bS,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",67,$CLJS.$H,1,658,658,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$H,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid exp clause.",$CLJS.m(yT)?yT.J:null])):
null));return $CLJS.m(a)?a:YP}(),yT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof zT?new $CLJS.ud(function(){return zT},$CLJS.K(Qha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[bS,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",67,$CLJS.SK,1,661,661,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.SK,$CLJS.JG,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid log clause.",$CLJS.m(zT)?zT.J:null])):null));return $CLJS.m(a)?a:gS}(),zT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==
typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(wO,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,
[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.El,jP),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,CQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:kQ}(),uU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof BT?new $CLJS.ud(function(){return BT},$CLJS.K(Tha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,
fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.iJ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vQ,"metabase/mbql/schema.cljc",65,$CLJS.iJ,1,680,680,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.iJ,kia,$CLJS.X($CLJS.El,qS),lia,$CLJS.X($CLJS.El,qS),$CLJS.Wj,$CLJS.X($CLJS.El,Pja)],null),$CLJS.yd,"Schema for a valid datetime-diff clause.",
$CLJS.m(BT)?BT.J:null])):null));return $CLJS.m(a)?a:vQ}(),BT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof CT?new $CLJS.ud(function(){return CT},$CLJS.K(Cja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",71,$CLJS.pK,1,685,685,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pK,$CLJS.CJ,$CLJS.X($CLJS.El,qS),$CLJS.Wj,$CLJS.X($CLJS.El,Yia),$CLJS.dk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,YR))],null),$CLJS.yd,"Schema for a valid temporal-extract clause.",$CLJS.m(CT)?CT.J:null])):null));return $CLJS.m(a)?a:NQ}(),CT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof DT?new $CLJS.ud(function(){return DT},$CLJS.K(Fia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PO,"metabase/mbql/schema.cljc",71,$CLJS.FI,1,691,691,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.FI,xQ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-year clause.",$CLJS.m(DT)?DT.J:null])):null));return $CLJS.m(a)?a:PO}(),DT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof ET?new $CLJS.ud(function(){return ET},$CLJS.K(sia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),
$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LQ,"metabase/mbql/schema.cljc",74,$CLJS.TK,1,694,694,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.TK,xQ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-quarter clause.",
$CLJS.m(ET)?ET.J:null])):null));return $CLJS.m(a)?a:LQ}(),ET],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof FT?new $CLJS.ud(function(){return FT},$CLJS.K(cja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",72,$CLJS.ZH,1,697,697,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZH,xQ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-month clause.",$CLJS.m(FT)?FT.J:null])):null));return $CLJS.m(a)?a:$Q}(),FT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==
typeof nO&&"undefined"!==typeof GT?new $CLJS.ud(function(){return GT},$CLJS.K(cka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",71,$CLJS.dK,1,700,700,
!0,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.dK,xQ,$CLJS.X($CLJS.El,qS),$CLJS.dk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,YR))],null),$CLJS.yd,"Schema for a valid get-week clause.",$CLJS.m(GT)?GT.J:null])):null));return $CLJS.m(a)?a:ZQ}(),GT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof HT?new $CLJS.ud(function(){return HT},$CLJS.K(Gha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,
$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",70,$CLJS.bK,1,704,704,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.bK,xQ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-day clause.",$CLJS.m(HT)?HT.J:
null])):null));return $CLJS.m(a)?a:UQ}(),HT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof IT?new $CLJS.ud(function(){return IT},$CLJS.K(Wia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QQ,"metabase/mbql/schema.cljc",78,$CLJS.kK,1,707,707,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.kK,xQ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-day-of-week clause.",$CLJS.m(IT)?IT.J:null])):null));return $CLJS.m(a)?a:QQ}(),IT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&
"undefined"!==typeof JT?new $CLJS.ud(function(){return JT},$CLJS.K(Via,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tO,"metabase/mbql/schema.cljc",71,$CLJS.xK,1,710,710,!0,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.xK,$CLJS.CJ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-hour clause.",$CLJS.m(JT)?JT.J:null])):null));return $CLJS.m(a)?a:tO}(),JT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof KT?new $CLJS.ud(function(){return KT},$CLJS.K(Hja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,
JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kS,"metabase/mbql/schema.cljc",73,$CLJS.hK,1,713,713,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.hK,$CLJS.CJ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-minute clause.",$CLJS.m(KT)?KT.J:null])):null));return $CLJS.m(a)?a:kS}(),KT],null),new $CLJS.S(null,
2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof LT?new $CLJS.ud(function(){return LT},$CLJS.K(Ria,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.pK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mQ,"metabase/mbql/schema.cljc",73,$CLJS.EI,1,716,716,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.EI,$CLJS.CJ,$CLJS.X($CLJS.El,qS)],null),$CLJS.yd,"Schema for a valid get-second clause.",$CLJS.m(LT)?LT.J:null])):null));return $CLJS.m(a)?a:mQ}(),LT],null)]));
ST=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof eT?new $CLJS.ud(function(){return eT},$CLJS.K(vja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dR,"metabase/mbql/schema.cljc",59,$CLJS.bI,1,600,600,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.bI,$CLJS.GD,$CLJS.X($CLJS.El,zR),$CLJS.TA,$CLJS.X($CLJS.El,nia),$CLJS.jD,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,tR))],null),$CLJS.yd,"Schema for a valid substring clause.",$CLJS.m(eT)?eT.J:null])):null));return $CLJS.m(a)?a:dR}(),eT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof gT?new $CLJS.ud(function(){return gT},
$CLJS.K(Zja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",54,$CLJS.CK,1,606,606,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.CK,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),
$CLJS.yd,"Schema for a valid trim clause.",$CLJS.m(gT)?gT.J:null])):null));return $CLJS.m(a)?a:uR}(),gT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof iT?new $CLJS.ud(function(){return iT},$CLJS.K(Xia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,
1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hR,"metabase/mbql/schema.cljc",55,$CLJS.HK,1,612,612,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.HK,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),$CLJS.yd,"Schema for a valid ltrim clause.",$CLJS.m(iT)?iT.J:null])):null));return $CLJS.m(a)?a:hR}(),iT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&
"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof hT?new $CLJS.ud(function(){return hT},$CLJS.K(wja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",
55,$CLJS.ZK,1,609,609,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZK,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),$CLJS.yd,"Schema for a valid rtrim clause.",$CLJS.m(hT)?hT.J:null])):null));return $CLJS.m(a)?a:AQ}(),hT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof lT?new $CLJS.ud(function(){return lT},$CLJS.K(Aha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,
$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VR,"metabase/mbql/schema.cljc",57,$CLJS.WK,1,621,621,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.WK,$CLJS.GD,$CLJS.X($CLJS.El,zR),Oha,$CLJS.X($CLJS.El,$CLJS.SP),$CLJS.BF,$CLJS.X($CLJS.El,$CLJS.SP)],null),$CLJS.yd,"Schema for a valid replace clause.",
$CLJS.m(lT)?lT.J:null])):null));return $CLJS.m(a)?a:VR}(),lT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof kT?new $CLJS.ud(function(){return kT},$CLJS.K(zja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,
new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tS,"metabase/mbql/schema.cljc",55,$CLJS.$u,1,618,618,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.$u,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),$CLJS.yd,"Schema for a valid lower clause.",$CLJS.m(kT)?kT.J:null])):null));return $CLJS.m(a)?a:tS}(),kT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==
typeof nO&&"undefined"!==typeof jT?new $CLJS.ud(function(){return jT},$CLJS.K(bka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HR,"metabase/mbql/schema.cljc",55,$CLJS.Ru,1,615,615,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Ru,$CLJS.GD,$CLJS.X($CLJS.El,zR)],null),$CLJS.yd,"Schema for a valid upper clause.",$CLJS.m(jT)?jT.J:null])):null));return $CLJS.m(a)?a:HR}(),jT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof mT?new $CLJS.ud(function(){return mT},$CLJS.K(nja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,
JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",56,$CLJS.pI,1,624,624,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.pI,UR,$CLJS.X($CLJS.El,zR),TR,$CLJS.X($CLJS.El,zR),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,zR))],null),$CLJS.yd,"Schema for a valid concat clause.",$CLJS.m(mT)?mT.J:null])):
null));return $CLJS.m(a)?a:uP}(),mT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof nT?new $CLJS.ud(function(){return nT},$CLJS.K(uja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.RQ,"null",$CLJS.lfa,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",74,$CLJS.PJ,1,627,627,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.PJ,$CLJS.GD,$CLJS.X($CLJS.El,zR),$CLJS.AF,$CLJS.X($CLJS.El,$CLJS.SP)],null),$CLJS.yd,"Schema for a valid regex-match-first clause.",$CLJS.m(nT)?nT.J:null])):null));return $CLJS.m(a)?a:NO}(),nT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&
"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof dT?new $CLJS.ud(function(){return dT},$CLJS.K(iR,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.RQ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xR,"metabase/mbql/schema.cljc",
58,$CLJS.RJ,1,597,597,new $CLJS.S(null,7,5,$CLJS.T,[$CLJS.RJ,UR,$CLJS.X($CLJS.El,sO),TR,$CLJS.X($CLJS.El,sO),bP,$CLJS.X($CLJS.El,$CLJS.X($CLJS.tk,sO))],null),$CLJS.yd,"Schema for a valid coalesce clause.",$CLJS.m(dT)?dT.J:null])):null));return $CLJS.m(a)?a:xR}(),dT],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(wO,new $CLJS.k(null,
1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.El,jP),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,
CQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:kQ}(),uU],null)]));$CLJS.vU=YN.l($CLJS.G([$CLJS.bg(gO,SS),WS,$CLJS.bg(gO,PS),QS,$CLJS.bg(gO,TS),aT,$CLJS.bg(gO,VS),$CLJS.ZS,$CLJS.bg(gO,$CLJS.$I),uU,$CLJS.Tu,$CLJS.NS]));wU=fO($CLJS.lD,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.NS],null)]));xU=fO($CLJS.NJ,$CLJS.G(["field",new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.Rj,$CLJS.NS],null)]));
yU=fO($CLJS.DJ,$CLJS.G(["field-or-expression",$CLJS.vU]));zU=fO($CLJS.RK,$CLJS.G(["field-or-expression",$CLJS.vU]));AU=fO($CLJS.NI,$CLJS.G(["field-or-expression",$CLJS.vU]));BU=fO($CLJS.tK,$CLJS.G(["field-or-expression",$CLJS.vU]));CU=fO($CLJS.qk,$CLJS.G(["field-or-expression",$CLJS.vU]));DU=fO($CLJS.Xl,$CLJS.G(["field-or-expression",$CLJS.vU]));EU=fO($CLJS.OK,$CLJS.G(["field-or-expression",$CLJS.vU,"pred",$CLJS.TT]));FU=fO($CLJS.fK,$CLJS.G(["pred",$CLJS.TT]));GU=fO($CLJS.ZJ,$CLJS.G(["pred",$CLJS.TT]));
HU=fO($CLJS.AK,$CLJS.G(["field-or-expression",$CLJS.vU]));IU=fO($CLJS.RI,$CLJS.G(["field-or-expression",$CLJS.vU]));JU=fO($CLJS.vK,$CLJS.G(["field-or-expression",$CLJS.vU]));KU=fO($CLJS.nJ,$CLJS.G(["field-or-expression",$CLJS.vU,"percentile",YS]));LU=fO($CLJS.cQ,$CLJS.G(["metric-id",$CLJS.LN($CLJS.G([$CLJS.PN,$CLJS.MN]))]));
MU=ZN($CLJS.bg(gO,SS),WS,hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof yU?new $CLJS.ud(function(){return yU},$CLJS.K(tja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",60,$CLJS.DJ,1,955,955,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.DJ,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid avg clause.",$CLJS.m(yU)?yU.J:null])):null));return $CLJS.m(a)?a:hQ}(),yU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof zU?
new $CLJS.ud(function(){return zU},$CLJS.K(zia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OR,"metabase/mbql/schema.cljc",64,$CLJS.RK,1,956,956,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RK,
IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid cum-sum clause.",$CLJS.m(zU)?zU.J:null])):null));return $CLJS.m(a)?a:OR}(),zU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof AU?new $CLJS.ud(function(){return AU},$CLJS.K(qia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],
[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",65,$CLJS.NI,1,957,957,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NI,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid distinct clause.",$CLJS.m(AU)?AU.J:null])):null));return $CLJS.m(a)?a:lQ}(),AU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof HU?new $CLJS.ud(function(){return HU},$CLJS.K(Rja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.lK,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vO,"metabase/mbql/schema.cljc",76,$CLJS.AK,1,971,971,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.AK,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid stddev clause.",$CLJS.m(HU)?HU.J:null])):null));return $CLJS.m(a)?a:vO}(),HU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof BU?new $CLJS.ud(function(){return BU},$CLJS.K(hja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,
$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",60,$CLJS.tK,1,958,958,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tK,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid sum clause.",$CLJS.m(BU)?BU.J:null])):
null));return $CLJS.m(a)?a:FO}(),BU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof CU?new $CLJS.ud(function(){return CU},$CLJS.K(Kha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.rl,"metabase/mbql/schema.cljc",60,$CLJS.qk,1,959,959,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.qk,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid min clause.",$CLJS.m(CU)?CU.J:null])):null));return $CLJS.m(a)?a:$CLJS.rl}(),CU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&
"undefined"!==typeof DU?new $CLJS.ud(function(){return DU},$CLJS.K(Fha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.am,"metabase/mbql/schema.cljc",60,$CLJS.Xl,1,960,960,new $CLJS.S(null,
3,5,$CLJS.T,[$CLJS.Xl,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid max clause.",$CLJS.m(DU)?DU.J:null])):null));return $CLJS.m(a)?a:$CLJS.am}(),DU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof LU?new $CLJS.ud(function(){return LU},$CLJS.K(mia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,
$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",26,$CLJS.cQ,1,989,989,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.cQ,Aia,$CLJS.X($CLJS.El,$CLJS.X($CLJS.wQ,aP,QR))],null),$CLJS.yd,"Schema for a valid metric clause.",$CLJS.m(LU)?LU.J:null])):null));return $CLJS.m(a)?a:tP}(),LU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==
typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof GU?new $CLJS.ud(function(){return GU},$CLJS.K(Pia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
nP,"metabase/mbql/schema.cljc",62,$CLJS.ZJ,1,968,968,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.ZJ,$CLJS.xl,$CLJS.X($CLJS.El,ZP)],null),$CLJS.yd,"Schema for a valid share clause.",$CLJS.m(GU)?GU.J:null])):null));return $CLJS.m(a)?a:nP}(),GU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof FU?new $CLJS.ud(function(){return FU},$CLJS.K(Mha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,
$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",68,$CLJS.fK,1,965,965,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.fK,$CLJS.xl,$CLJS.X($CLJS.El,ZP)],null),$CLJS.yd,"Schema for a valid count-where clause.",$CLJS.m(FU)?
FU.J:null])):null));return $CLJS.m(a)?a:DO}(),FU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof EU?new $CLJS.ud(function(){return EU},$CLJS.K(jka,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,
1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",66,$CLJS.OK,1,962,962,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.OK,IR,$CLJS.X($CLJS.El,BR),$CLJS.xl,$CLJS.X($CLJS.El,ZP)],null),$CLJS.yd,"Schema for a valid sum-where clause.",$CLJS.m(EU)?EU.J:null])):null));return $CLJS.m(a)?a:iQ}(),EU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&
"undefined"!==typeof nO&&"undefined"!==typeof uU?new $CLJS.ud(function(){return uU},$CLJS.K(wO,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",61,$CLJS.$I,
1,916,916,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.$I,$CLJS.wC,$CLJS.X($CLJS.El,jP),$CLJS.Nk,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,CQ))],null),$CLJS.yd,"Schema for a valid case clause.",$CLJS.m(uU)?uU.J:null])):null));return $CLJS.m(a)?a:kQ}(),uU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof JU?new $CLJS.ud(function(){return JU},$CLJS.K(Kja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,
$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NR,"metabase/mbql/schema.cljc",68,$CLJS.vK,1,977,977,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.vK,IR,$CLJS.X($CLJS.El,BR)],null),$CLJS.yd,"Schema for a valid median clause.",$CLJS.m(JU)?JU.J:null])):
null));return $CLJS.m(a)?a:NR}(),JU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof KU?new $CLJS.ud(function(){return KU},$CLJS.K(bja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.FJ,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,
[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",72,$CLJS.nJ,1,980,980,new $CLJS.S(null,5,5,$CLJS.T,[$CLJS.nJ,IR,$CLJS.X($CLJS.El,BR),$CLJS.nJ,$CLJS.X($CLJS.El,tR)],null),$CLJS.yd,"Schema for a valid percentile clause.",$CLJS.m(KU)?KU.J:null])):null));return $CLJS.m(a)?a:rR}(),KU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&
"undefined"!==typeof nO&&"undefined"!==typeof IU?new $CLJS.ud(function(){return IU},$CLJS.K(Wha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",77,$CLJS.RI,1,974,974,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.RI,IR,$CLJS.X($CLJS.El,
BR)],null),$CLJS.yd,"Schema for a valid var clause.",$CLJS.m(IU)?IU.J:null])):null));return $CLJS.m(a)?a:uO}(),IU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof xU?new $CLJS.ud(function(){return xU},$CLJS.K(Hha,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,
new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$P,"metabase/mbql/schema.cljc",74,$CLJS.NJ,1,945,945,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.NJ,$CLJS.GQ,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,ZO))],null),$CLJS.yd,"Schema for a valid cum-count clause.",$CLJS.m(xU)?xU.J:null])):null));return $CLJS.m(a)?a:$P}(),xU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=
fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof wU?new $CLJS.ud(function(){return wU},$CLJS.K(oia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([eQ,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JR,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.gI,"null"],null),null),$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$CLJS.ck,"metabase/mbql/schema.cljc",70,$CLJS.lD,1,944,944,!0,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.lD,$CLJS.GQ,$CLJS.X($CLJS.El,$CLJS.X($CLJS.AN,ZO))],null),$CLJS.yd,"Schema for a valid count clause.",$CLJS.m(wU)?wU.J:null])):null));return $CLJS.m(a)?a:$CLJS.ck}(),wU],null)])));
NU=aO(new $CLJS.ud(function(){return MU},aka,$CLJS.Sh([$CLJS.nm,$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[!0,$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dka,"metabase/mbql/schema.cljc",35,1,995,995,$CLJS.yd,null,$CLJS.m(MU)?MU.J:null])));Jka=$CLJS.Pf([UN($CLJS.V),$CLJS.MN,UN($CLJS.UI),$CLJS.MN,$CLJS.IN,$CLJS.DN]);
$CLJS.OU=fO($CLJS.qO,$CLJS.G(["aggregation",NU,"options",Jka]));$CLJS.XS=ZN($CLJS.bg(gO,$CLJS.qO),$CLJS.OU,NU);PU=fO($CLJS.GJ,$CLJS.G(["field",$CLJS.OS]));QU=fO($CLJS.tJ,$CLJS.G(["field",$CLJS.OS]));
$CLJS.RU=hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof PU?new $CLJS.ud(function(){return PU},$CLJS.K(Nja,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LP,"metabase/mbql/schema.cljc",15,$CLJS.GJ,1,1033,1033,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.GJ,$CLJS.GQ,$CLJS.X($CLJS.El,oP)],null),$CLJS.yd,"Schema for a valid asc clause.",$CLJS.m(PU)?PU.J:null])):null));return $CLJS.m(a)?a:LP}(),PU],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof QU?new $CLJS.ud(function(){return QU},$CLJS.K(yia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),
$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",16,$CLJS.tJ,1,1034,1034,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.tJ,$CLJS.GQ,$CLJS.X($CLJS.El,oP)],null),$CLJS.yd,"Schema for a valid desc clause.",$CLJS.m(QU)?QU.J:null])):null));return $CLJS.m(a)?a:jR}(),QU],null)]));
WN.l($CLJS.G([$CLJS.mI,$CLJS.QI,$CLJS.dI,$CLJS.mz,$CLJS.HG,xQ]));SU=$CLJS.Pf([UN($CLJS.Gk),$CLJS.MN,$CLJS.V,$CLJS.MN,$CLJS.UI,$CLJS.MN,$CLJS.IN,$CLJS.DN]);Kka=$CLJS.en.l($CLJS.G([SU,$CLJS.Pf([$CLJS.Wk,$CLJS.TM($CLJS.mI),$CLJS.fI,$CLJS.MN,kja,$CLJS.PN,UN($CLJS.OQ),$CLJS.PN])]));Lka=$CLJS.en.l($CLJS.G([SU,new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.TM($CLJS.QI),$CLJS.JK,$CLJS.PN],null)]));TU=$CLJS.en.l($CLJS.G([SU,$CLJS.Pf([UN($CLJS.Pj),$CLJS.DN,UN(iia),$CLJS.FN])]));
Mka=$CLJS.en.l($CLJS.G([TU,$CLJS.Pf([$CLJS.Wk,$CLJS.TM($CLJS.dI),$CLJS.dI,LS,$CLJS.dJ,aO(new $CLJS.ud(function(){return UU},$ja,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oka,"metabase/mbql/schema.cljc",34,1,1130,!0,1130,$CLJS.yd,null,$CLJS.m(UU)?UU.J:null]))),UN($CLJS.Nk),$CLJS.Pf([$CLJS.IN,
$CLJS.DN])])]));Nka=$CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,4,[xQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.HG,null],null),null));Oka=$CLJS.en.l($CLJS.G([TU,new $CLJS.k(null,1,[$CLJS.Wk,Nka],null)]));Pka=YN.l($CLJS.G([function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.dI)},Mka,function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.mI)},Kka,function(a){return $CLJS.F.h($CLJS.Wk.g(a),$CLJS.QI)},Lka,$CLJS.Tu,Oka]));
Qka=$CLJS.ZM($CLJS.Pf([$CLJS.MN,Pka]),function(a){return $CLJS.Wf(function(b){var c=$CLJS.J(b,0,null);b=$CLJS.J(b,1,null);return $CLJS.F.h(c,$CLJS.V.g(b))},a)},"keys in template tag map must match the :name of their values");VU=$CLJS.Pf([$CLJS.xO,$CLJS.DN,UN($CLJS.kR),Qka,UN(pP),$CLJS.cO($CLJS.MN),$CLJS.IN,$CLJS.DN]);
XU=ZN($CLJS.Lm.h($CLJS.oe,$CLJS.WQ),$CLJS.SN(VU,new $CLJS.k(null,1,[$CLJS.xO,$CLJS.WQ],null)),aO(new $CLJS.ud(function(){return $CLJS.WU},Jia,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),Lja,"metabase/mbql/schema.cljc",25,1,1212,!0,1212,$CLJS.yd,null,$CLJS.m($CLJS.WU)?$CLJS.WU.J:null]))));
YU=$CLJS.Pf([$CLJS.V,$CLJS.MN,$CLJS.uJ,$CLJS.NN,jja,$CLJS.MN,UN($CLJS.EP),$CLJS.cO($CLJS.ON),UN($CLJS.gP),$CLJS.cO($CLJS.vha),$CLJS.DN,$CLJS.DN]);$CLJS.ZU=/^card__[1-9]\d*$/;$U=$CLJS.LN($CLJS.G([$CLJS.PN,$CLJS.ZU]));Rka=$CLJS.R.h(WN,new $CLJS.ni(null,new $CLJS.k(null,4,[$CLJS.KR,null,$CLJS.oO,null,$CLJS.zQ,null,$CLJS.pR,null],null),null));
Ska=$CLJS.ZM($CLJS.Sh([$CLJS.IN,UN($CLJS.QP),UN($CLJS.aQ),UN($CLJS.yP),UN($CLJS.zO),$CLJS.cS,UN($CLJS.nR),UN($CLJS.lR),UN($CLJS.XK)],[$CLJS.DN,$CLJS.cO(new $CLJS.S(null,1,5,$CLJS.T,[YU],null)),$U,$CLJS.MN,$CLJS.XM($CLJS.LN($CLJS.G([WN.l($CLJS.G([$CLJS.RD,$CLJS.wF])),aO(new $CLJS.ud(function(){return aV},Fja,$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,$CLJS.Ak,$CLJS.Xk,fR,$CLJS.tm,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pja,"metabase/mbql/schema.cljc",16,1,1259,!0,1259,$CLJS.yd,null,$CLJS.m(aV)?aV.J:null])))])),"Valid Join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."),$CLJS.TT,XU,$CLJS.cO($CLJS.PN),Rka]),$CLJS.Lm.h($CLJS.Mm.h($CLJS.aQ,$CLJS.nR),$CLJS.$f($CLJS.Lm.h($CLJS.aQ,$CLJS.nR))),"Joins must have either a `source-table` or `source-query`, but not both.");
Tka=$CLJS.ZM(iO(new $CLJS.S(null,1,5,$CLJS.T,[Ska],null)),function(a){return jO($CLJS.ug($CLJS.Hb,$CLJS.rg.h($CLJS.yP,a)))},"All join aliases must be unique.");aV=$CLJS.XM($CLJS.ZM(iO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NS],null)),jO,"distinct"),"Distinct, non-empty sequence of Field clauses");
$CLJS.WU=$CLJS.ZM($CLJS.ZM($CLJS.Sh([$CLJS.IN,UN($CLJS.aS),UN($CLJS.QP),UN($CLJS.XO),UN($CLJS.aQ),UN($CLJS.LO),UN($CLJS.zO),UN($CLJS.PP),UN($CLJS.IP),UN($CLJS.nR),UN($CLJS.uS),UN(XQ),UN($CLJS.RQ)],[$CLJS.DN,$CLJS.ZM(iO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.RU],null)),jO,"distinct"),$CLJS.cO(new $CLJS.S(null,1,5,$CLJS.T,[YU],null)),Tka,$U,$CLJS.TT,aV,iO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.XS],null)),iO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.NS],null)),XU,$CLJS.QN,new $CLJS.k(null,2,[XQ,$CLJS.PN,bia,$CLJS.PN],
null),$CLJS.Pf([$CLJS.MN,$CLJS.vU])]),function(a){return $CLJS.F.h(1,$CLJS.E($CLJS.li(a,new $CLJS.S(null,2,5,$CLJS.T,[$CLJS.nR,$CLJS.aQ],null))))},"Query must specify either `:source-table` or `:source-query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.IP);b=$CLJS.M.h(b,$CLJS.zO);return $CLJS.je($CLJS.Fz.h($CLJS.si(a),$CLJS.si(b)))},"Fields specified in `:breakout` should not be specified in `:fields`; this is implied.");
bV=$CLJS.Sh([$CLJS.GR,xQ,mP,fP,RP,oS,BP,$CLJS.nS,$CLJS.mz,AP,yO,yR,dS,$CLJS.HO,$R,lP,$CLJS.XP,zP,$CLJS.Gk,$CLJS.xP,rO,$CLJS.UO,$CLJS.wP,tQ,$CLJS.kk,$CLJS.HG,rP],[new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.GR,null,xQ,null,$CLJS.mz,null,$CLJS.kk,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.GR,null,xQ,null,$CLJS.nS,null,$CLJS.XP,null,$CLJS.Gk,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,
$CLJS.QJ,FR,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[mP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,rS,$CLJS.QJ,FR,KP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.GR,null,fP,null,$CLJS.mz,null,$CLJS.Gk,null,rO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,$CLJS.QJ,BO,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[RP,null],null),null)],null),new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,1,[oS,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,rS,$CLJS.QJ,Dja,KP,new $CLJS.ni(null,
new $CLJS.k(null,1,[BP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.nS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,rS,KP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.GR,null,fP,null,$CLJS.mz,null,$CLJS.Gk,null,rO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,rS,$CLJS.QJ,BO,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[AP,null],null),null)],null),new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,1,[yO,null],null),null)],
null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,$CLJS.QJ,FR,KP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.GR,null,oS,null,yO,null,yR,null,zP,null,$CLJS.Gk,null,rO,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,$CLJS.QJ,BO,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[dS,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.nS,null,$CLJS.HO,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,$CLJS.QJ,BO,KP,new $CLJS.ni(null,
new $CLJS.k(null,1,[$R,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,$CLJS.Nl,$CLJS.QJ,BO,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[lP,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,5,[$CLJS.GR,null,xQ,null,$CLJS.nS,null,$CLJS.XP,null,$CLJS.Gk,null],null),null)],null),new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,1,[zP,null],null),null)],null),new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,1,[$CLJS.Gk,null],null),null)],
null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.nS,null,$CLJS.xP,null],null),null)],null),new $CLJS.k(null,1,[KP,new $CLJS.ni(null,new $CLJS.k(null,1,[rO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.nS,null,$CLJS.UO,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,xQ,KP,new $CLJS.ni(null,new $CLJS.k(null,2,[$CLJS.nS,null,$CLJS.wP,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,rS,$CLJS.QJ,FR,
KP,new $CLJS.ni(null,new $CLJS.k(null,1,[tQ,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.kk,KP,new $CLJS.ni(null,new $CLJS.k(null,3,[$CLJS.GR,null,$CLJS.Gk,null,$CLJS.kk,null],null),null)],null),new $CLJS.k(null,2,[$CLJS.Wk,$CLJS.Nl,KP,new $CLJS.ni(null,new $CLJS.k(null,8,[$CLJS.GR,null,oS,null,yO,null,yR,null,zP,null,$CLJS.Gk,null,rO,null,$CLJS.HG,null],null),null)],null),new $CLJS.k(null,3,[$CLJS.Wk,rS,$CLJS.QJ,BO,KP,new $CLJS.ni(null,new $CLJS.k(null,1,[rP,null],null),null)],null)]);
Uka=$CLJS.R.h(WN,$CLJS.gi(bV));UU=$CLJS.R.h(WN,$CLJS.nf($CLJS.wF,$CLJS.gi(bV)));cV=fO(Sha,$CLJS.G(["tag-name",$CLJS.LN($CLJS.G([$CLJS.MN,new $CLJS.k(null,1,[$CLJS.Gk,$CLJS.MN],null)]))]));dV=fO($CLJS.dI,$CLJS.G(["target",$CLJS.LN($CLJS.G([$CLJS.NS,cV]))]));eV=fO(vP,$CLJS.G(["target",cV]));
Vka=$CLJS.LN($CLJS.G([$CLJS.NS,hO($CLJS.G([new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof dV?new $CLJS.ud(function(){return dV},$CLJS.K($ia,new $CLJS.k(null,1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aR,"metabase/mbql/schema.cljc",21,$CLJS.dI,1,1557,1557,new $CLJS.S(null,3,5,$CLJS.T,[$CLJS.dI,$CLJS.aL,$CLJS.X($CLJS.El,$CLJS.X($CLJS.wQ,ZO,JP))],null),$CLJS.yd,"Schema for a valid dimension clause.",$CLJS.m(dV)?dV.J:null])):null));return $CLJS.m(a)?a:aR}(),dV],null),new $CLJS.S(null,2,5,$CLJS.T,[function(){var a=fQ.g($CLJS.fe("undefined"!==typeof $CLJS.lO&&"undefined"!==typeof mO&&"undefined"!==typeof nO&&"undefined"!==typeof eV?new $CLJS.ud(function(){return eV},$CLJS.K(qja,new $CLJS.k(null,
1,[$CLJS.Pl,!0],null)),$CLJS.Sh([$CLJS.Dk,$CLJS.V,$CLJS.dm,$CLJS.ak,fQ,$CLJS.Ak,$CLJS.Xk,$CLJS.tm,JO,$CLJS.Zj,$CLJS.W,$CLJS.cm],[$CLJS.K(sR,new $CLJS.k(null,1,[$CLJS.W,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sS,"metabase/mbql/schema.cljc",20,vP,1,1560,1560,new $CLJS.S(null,3,5,$CLJS.T,[vP,$CLJS.aL,$CLJS.X($CLJS.El,JP)],null),$CLJS.yd,"Schema for a valid variable clause.",$CLJS.m(eV)?eV.J:null])):null));return $CLJS.m(a)?a:sS}(),
eV],null)]))]));Wka=$CLJS.Pf([$CLJS.Wk,Uka,UN($CLJS.Gk),$CLJS.MN,UN($CLJS.aL),Vka,UN($CLJS.ql),$CLJS.DN,UN($CLJS.V),$CLJS.MN,UN(Zha),$CLJS.MN,UN($CLJS.Pj),$CLJS.DN,$CLJS.IN,$CLJS.DN]);Xka=new $CLJS.S(null,1,5,$CLJS.T,[Wka],null);Yka=$CLJS.Pf([UN(hka),$CLJS.MN,$CLJS.IN,$CLJS.DN]);Zka=$CLJS.ZM($CLJS.Pf([UN(jQ),$CLJS.QN,UN(EO),$CLJS.QN,$CLJS.IN,$CLJS.DN]),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,jQ);b=$CLJS.M.h(b,EO);return $CLJS.Gb($CLJS.m(a)?b:a)?!0:a>=b},"max-results-bare-rows must be less or equal to than max-results");
$ka=$CLJS.Pf([UN(Mja),$CLJS.FN,UN(Uha),$CLJS.FN,UN(Bha),$CLJS.FN,UN(aia),$CLJS.FN,UN(Eja),$CLJS.cO($CLJS.FN),UN(Eha),$CLJS.cO($CLJS.FN),UN(Tja),$CLJS.cO($CLJS.FN),$CLJS.IN,$CLJS.DN]);ala=WN.l($CLJS.G([mja,pP,aja,rja,Mia,Pha,Vha,hia,xia,Aja,rka,Bia,tia]));
bla=$CLJS.Sh([UN(ika),UN(Dia),UN($CLJS.qP),UN(Jja),UN(Kia),UN(Cha),UN(fka),UN($CLJS.JK),UN($CLJS.OO)],[$CLJS.cO($CLJS.MN),$CLJS.cO($CLJS.PN),$CLJS.cO(new $CLJS.S(null,1,5,$CLJS.T,[$CLJS.Pf([$CLJS.DN,$CLJS.DN])],null)),$CLJS.cO($CLJS.PN),$CLJS.cO($CLJS.PN),$CLJS.cO($CLJS.DN),$CLJS.cO($CLJS.Pf([$CLJS.DN,$CLJS.DN])),$CLJS.cO($CLJS.PN),$CLJS.cO(ala)]);cla=$CLJS.LN($CLJS.G([$CLJS.TM(-1337),$CLJS.PN]));
$CLJS.fV=$CLJS.ZM($CLJS.ZM($CLJS.ZM($CLJS.Sh([$CLJS.IN,UN($CLJS.WQ),UN($CLJS.oR),UN($CLJS.$D),UN(Zia),UN(Oia),$CLJS.Wk,UN($CLJS.xO),$CLJS.OQ,UN($CLJS.QO)],[$CLJS.DN,VU,$CLJS.cO(Yka),$CLJS.cO(bla),$CLJS.cO(Zka),$CLJS.cO($ka),WN.l($CLJS.G([$CLJS.xO,$CLJS.WQ])),$CLJS.WU,cla,Xka]),$CLJS.Lm.h($CLJS.Mm.h($CLJS.WQ,$CLJS.xO),$CLJS.$f($CLJS.Lm.h($CLJS.WQ,$CLJS.xO))),"Query must specify either `:native` or `:query`, but not both."),function(a){var b=$CLJS.Qf(a);a=$CLJS.M.h(b,$CLJS.WQ);var c=$CLJS.M.h(b,$CLJS.xO);
b=$CLJS.M.h(b,$CLJS.Wk);b=b instanceof $CLJS.N?b.S:null;switch(b){case "native":return a;case "query":return c;default:throw Error(["No matching clause: ",$CLJS.p.g(b)].join(""));}},"Native queries must specify `:native`; MBQL queries must specify `:query`."),$CLJS.$f($CLJS.QP),"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)");
(function(a){var b=$CLJS.dO(a);return function(c){var d=b(c);if($CLJS.m(d))throw $CLJS.zj($CLJS.XL("Value does not match schema: %s",$CLJS.G([$CLJS.aj.l($CLJS.G([d]))])),new $CLJS.k(null,4,[$CLJS.Wk,$CLJS.dha,$CLJS.bm,a,$CLJS.ql,c,$CLJS.Wu,d],null));return c}})($CLJS.fV);