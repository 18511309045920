var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var XE=function(){},YE=function(a){var b=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(b),$CLJS.p.g(" ")].join(""),a],null)},ZE=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.WE,$CLJS.UE)),";background-color:",$CLJS.p.g($CLJS.M.h($CLJS.WE,c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],
null)},$E=function(a,b,c){var d=$CLJS.J(a,0,null);a=$CLJS.J(a,1,null);return new $CLJS.S(null,2,5,$CLJS.T,[[$CLJS.p.g(d),"%c",$CLJS.p.g(b),"%c"].join(""),$CLJS.kf.l(a,["color:",$CLJS.p.g($CLJS.M.h($CLJS.WE,c))].join(""),$CLJS.G(["color:black"]))],null)},aF=function(a){return $CLJS.M.h($CLJS.ND,a).value},Xea=function(a){var b=aF(a);return $CLJS.m(function(){var c=aF($CLJS.cE);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.IE:$CLJS.m(function(){var c=aF($CLJS.bE);return $CLJS.Gm.h?
$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.SE:$CLJS.m(function(){var c=aF($CLJS.$D);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.LE:$CLJS.m(function(){var c=aF($CLJS.VD);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.RE:$CLJS.m(function(){var c=aF($CLJS.aE);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.TE:$CLJS.m(function(){var c=aF($CLJS.XD);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.OE:$CLJS.m(function(){var c=
aF($CLJS.SD);return $CLJS.Gm.h?$CLJS.Gm.h(c,b):$CLJS.Gm.call(null,c,b)}())?$CLJS.PE:$CLJS.QE},Yea=function(a){function b(c,d){return d>=c}a=aF(a);if(b(aF($CLJS.cE),a))return"error";if(b(aF($CLJS.bE),a))return"warn";if(b(aF($CLJS.$D),a))return"info";b(aF($CLJS.VD),a);return"log"},bF=function(a){return function(b){var c=$CLJS.Qf(b),d=$CLJS.M.h(c,$CLJS.TD),e=$CLJS.M.h(c,$CLJS.Yu);b=$CLJS.M.h(c,$CLJS.dE);e=Yea(e);e=$CLJS.Ua(console,e);e=$CLJS.m(e)?e:console.log;$CLJS.R.h(e,a.g?a.g(c):a.call(null,c));
return $CLJS.m(b)?(c=["[",$CLJS.p.g(d),"]"].join(""),d=$CLJS.p.g(b),b=b.stack,e.D?e.D(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},cF=function cF(a,b){for(;;){if($CLJS.F.h($CLJS.HE,b))return $E(a,", ",$CLJS.QE);if($CLJS.F.h($CLJS.JE,b))return YE(a);if(b instanceof $CLJS.N)return $E(a,b,$CLJS.LE);if(b instanceof $CLJS.w)return $E(a,b,$CLJS.RE);if("string"===typeof b)return $E(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.KE);if($CLJS.Ig(b)){var d=YE(function(){var f=a,g=$CLJS.tc(b);return cF.h?cF.h(f,g):cF.call(null,
f,g)}()),e=$CLJS.uc(b);return cF.h?cF.h(d,e):cF.call(null,d,e)}if(b instanceof $CLJS.k||b instanceof $CLJS.Rh)return d=a,d=$E(d,"{",$CLJS.VE),d=$CLJS.Sb(cF,d,$CLJS.pg($CLJS.HE,b)),$E(d,"}",$CLJS.VE);if($CLJS.oe(b))return d=a,d=$E(d,["#",$CLJS.p.g(function(){var f=$CLJS.Ob(b),g=f.name;return $CLJS.je(g)?$CLJS.aj.l($CLJS.G([f])):g}())," "].join(""),$CLJS.ME),d=$E(d,"{",$CLJS.VE),d=$CLJS.Sb(cF,d,$CLJS.pg($CLJS.HE,b)),$E(d,"}",$CLJS.VE);if($CLJS.le(b))return d=a,d=$E(d,"#{",$CLJS.VE),d=$CLJS.Sb(cF,d,
$CLJS.pg($CLJS.JE,b)),$E(d,"}",$CLJS.VE);if($CLJS.qe(b))return d=a,d=$E(d,"[",$CLJS.VE),d=$CLJS.Sb(cF,d,$CLJS.pg($CLJS.JE,b)),$E(d,"]",$CLJS.VE);if(b instanceof $CLJS.ah)d=$E(a,"#queue ",$CLJS.ME),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else{if($CLJS.we(b))return d=a,d=$E(d,"(",$CLJS.ME),d=$CLJS.Sb(cF,d,$CLJS.pg($CLJS.JE,b)),$E(d,")",$CLJS.ME);if(null!=b?b.K&16384||$CLJS.t===b.Rj||(b.K?0:$CLJS.Nb(XE,b)):$CLJS.Nb(XE,b))d=$E(a,"#atom ",$CLJS.ME),e=$CLJS.r(b),a=d,b=e;else if($CLJS.wj(b))d=$E(a,"#uuid ",$CLJS.ME),
e=$CLJS.p.g(b),a=d,b=e;else if($CLJS.Ib(b))d=$E(a,"#js ",$CLJS.ME),e=$CLJS.Sb(function(f,g){return function(l,n){return $CLJS.U.j(l,$CLJS.Oi.g(n),$CLJS.Ua(g,n))}}(a,b),$CLJS.P,Object.keys(b)),a=d,b=e;else if($CLJS.Db(b))d=$E(a,"#js ",$CLJS.ME),e=$CLJS.oh.h($CLJS.Lg,b),a=d,b=e;else return $E(a,$CLJS.aj.l($CLJS.G([b])),$CLJS.NE)}}};$CLJS.Zea=bF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.Yu);var b=$CLJS.M.h(a,$CLJS.TD),c=$CLJS.M.h(a,$CLJS.yk);$CLJS.M.h(a,$CLJS.dE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),c],null)});
$CLJS.$ea=bF(function(a){var b=$CLJS.Qf(a),c=$CLJS.M.h(b,$CLJS.Yu);a=$CLJS.M.h(b,$CLJS.TD);var d=$CLJS.M.h(b,$CLJS.yk);$CLJS.M.h(b,$CLJS.dE);b=Xea(c);d=cF(YE(ZE(ZE(ZE(new $CLJS.S(null,2,5,$CLJS.T,["",$CLJS.Lg],null),"[",b),a,b),"]",b)),d);a=$CLJS.J(d,0,null);d=$CLJS.J(d,1,null);return $CLJS.nf(a,d)});
$CLJS.afa=bF(function(a){a=$CLJS.Qf(a);$CLJS.M.h(a,$CLJS.Yu);var b=$CLJS.M.h(a,$CLJS.TD),c=$CLJS.M.h(a,$CLJS.yk);$CLJS.M.h(a,$CLJS.dE);return new $CLJS.S(null,2,5,$CLJS.T,[["[",$CLJS.p.g(b),"]"].join(""),$CLJS.aj.l($CLJS.G([c]))],null)});